import { axios, handleApiErrors, getToken } from '../helpers'

export default () => {
  const token = getToken()
  const app_id = window.appConfig.app_id

  const params = { session_id: token, app_id }

  return axios({
    url: 'profile',
    method: 'GET',
    params,
    headers: { 'content-type': 'application/x-www-form-urlencoded' }
  }).then(handleApiErrors)
}
