import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { setSelectedEvent } from '../../ducks/calendar'
import TicketDetails from './TicketDetails'

import closeSvg from '../../images/icons/close.svg'

const StyledModalBackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 110;

  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledDetailWrapper = styled.div`
  background-color: #fff;
  padding-top: 46px;
  position: relative;

  width: 254px;
  min-width: 254px;
`

const CloseIcon = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  height: 18px;
  width: 18px;
  cursor: pointer;

  background: url(${closeSvg}) center center no-repeat;
  background-size: contain;
`

class TicketDetailsModal extends Component {
  render() {
    const { selectedEvent } = this.props.calendar

    if (!selectedEvent) {
      return null
    }

    return (
      <React.Fragment>
        <StyledModalBackgroundWrapper onClick={this.closeModal} />
        <StyledModalWrapper>
          <StyledDetailWrapper>
            <TicketDetails />
            <CloseIcon onClick={this.closeModal} />
          </StyledDetailWrapper>
        </StyledModalWrapper>
      </React.Fragment>
    )
  }

  closeModal = () => {
    this.props.setSelectedEvent(null)
  }
}

export default connect(
  ({ calendar }) => ({ calendar }),
  { setSelectedEvent }
)(TicketDetailsModal)
