import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Button from '../../components/UI/Button'

import closeSvg from '../../images/icons/close.svg'

import GuestSelect from '../BookingModal/GuestSelect'
import OfferSelect from '../BookingModal/OfferSelect'
import BookingCalendar from '../BookingModal/BookingCalendar'
import AvailableTimeSelect from '../BookingModal/AvailableTimeSelect'

import { toggleModal, makeBooking } from '../../ducks/booking'

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  ${(props) =>
    props.withBackground &&
    css`
      background-color: rgba(0, 0, 0, 0.5);
    `}
`

const StyledBookingModal = styled.div`
  z-index: 10000;

  ${(props) =>
    props.centered
      ? css`
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : css`
          position: absolute;
          top: ${(props) => props.top}px;
          left: ${(props) => props.left}px;
        `}

  width: 352px;

  border-radius: 10px;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.2);

  background-color: #fff;
  padding: 27px 21px 0 21px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CloseIcon = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  height: 18px;
  width: 18px;
  cursor: pointer;

  background: url(${closeSvg}) center center no-repeat;
  background-size: contain;
`

class BookingModal extends Component {
  render() {
    const { toggleModal, booking, ui, makeBooking, t } = this.props
    const {
      modal: { isOpen, x, y },
      clients,
      offers,
      bookingTime,
      isRebooking
    } = booking
    const { isMobile } = ui

    if (!isOpen) return null

    const modalWidth = 352
    const modalHeight = 405

    const top = window.innerHeight - (y + modalHeight) > 0 ? y : y - modalHeight
    const left = window.innerWidth - (x + modalWidth) > 0 ? x : x - modalWidth

    const centered = isRebooking || isMobile

    return (
      <React.Fragment>
        <BackgroundWrapper
          withBackground={isMobile}
          onClick={() => toggleModal({})}
        />
        <StyledBookingModal centered={centered} top={top} left={left}>
          <GuestSelect />
          <OfferSelect />
          <BookingCalendar />
          <AvailableTimeSelect />
          <ButtonWrapper>
            <Button
              disabled={!clients.clientId || !offers.offerId || !bookingTime}
              onClick={() => makeBooking()}
              marBot={isMobile ? 28 : 14}
              marTop={isMobile ? 18 : 0}
              stretch={isMobile}
              size={isMobile ? 'big' : 'small'}
            >
              {t('booking:bookNow')}
            </Button>
          </ButtonWrapper>

          {isMobile && <CloseIcon onClick={() => toggleModal({})} />}
        </StyledBookingModal>
      </React.Fragment>
    )
  }
}

export default compose(
  connect(
    ({ booking, ui }) => ({ booking, ui }),
    { toggleModal, makeBooking }
  ),
  withTranslation()
)(BookingModal)
