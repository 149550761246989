import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import moment from 'moment'

const StyledWeekHeader = styled.div`
  margin: 14px 0;

  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #bdbdbd;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-transform: uppercase;

  cursor: pointer;

  .date {
    font-size: 20px;

    width: 31px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    @media (max-width: 930px) {
      font-size: 16px;
    }
  }

  ${(props) =>
    props.active &&
    css`
      color: #4192ec;

      .date {
        color: #fff;
        background-color: #4192ec;
      }
    `}
`

const WeekHeader = ({ date, ui }) => {
  const { isMobile } = ui
  const momentDate = moment(date)

  const dayName = isMobile
    ? momentDate.format('dddd')[0]
    : momentDate.format('dddd')
  const dateNumber = momentDate.format('DD')

  const isActive = moment().isSame(momentDate, 'day')

  return (
    <StyledWeekHeader active={isActive}>
      <span>{dayName}</span>
      <span className="date">{dateNumber}</span>
    </StyledWeekHeader>
  )
}

export default connect(({ ui }) => ({ ui }))(WeekHeader)
