import { axios, handleApiErrors, getToken } from '../helpers'

export default ({ card_id = '' }) => {
  const token = getToken()
  const app_id = window.appConfig.app_id

  const params = { session_id: token, card_id, app_id }

  return axios({
    url: 'offers',
    method: 'GET',
    params
  }).then(handleApiErrors)
}
