export default {
  auth: {
    byEmail: 'Authentication by email and password',
    byCard: 'Authentication by card',
    login: 'Login',
    cardNumber: 'Card number',
    email: 'Email',
    password: 'Password',
    logout: 'Log out'
  },
  calendar: {
    today: 'Today',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    controlData: (month, year) => `${month} of ${year}`,
    showMore: (count) => `+ ${count} more`
  },
  booking: {
    loading: 'Loading',
    bookNow: 'Book now',
    details: 'Booking details',
    noEvent: 'No selected event',
    minutesShort: 'min',
    status: 'Status',
    markCompleted: 'Mark completed',
    rebook: 'Rebook',
    time: 'Time',
    chooseTime: 'Choose booking time',
    noTimeAvail: 'No time available',
    client: 'Guest',
    noClientSelected: 'No guest selected',
    noClientFound: 'Clients not found',
    offers: 'Offers',
    noOffersSelected: 'No offer selected',
    noOffersFound: 'Offers not found'
  },
  schedule: {
    upcomingToday: 'Upcoming today',
    noUpcoming: 'No bookings for today'
  },
  statuses: {
    COMPLETE: 'Book',
    INCOMPLETE: 'Incomplete',
    IN_PROCESS: 'In process',
    CONFIRMED: 'Confirmed',
    NO_SHOW: 'No show',
    CANCEL: 'Cancel',
    DONE: 'Done',
    UNDEFINED: 'Undefined',
    TENTATIVE: 'Tentative'
  }
}
