import React from 'react'
import { withTranslation } from 'react-i18next'
import Input from '../../components/UI/Input'

const CardForm = ({ card, handleFormChange, t }) => (
  <Input
    autoFocus
    label={t('auth:cardNumber')}
    id="card"
    name="card"
    value={card}
    placeholder={t('auth:cardNumber')}
    onChangeHandler={handleFormChange('card')}
  />
)

export default withTranslation()(CardForm)
