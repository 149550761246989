import { axios, handleApiErrors, getToken } from '../helpers'

export default ({
  offerId,
  card_id,
  from = null,
  to = null,
  from_ms = null,
  to_ms = null
}) => {
  const token = getToken()
  const app_id = window.appConfig.app_id

  const params = {
    session_id: token,
    card_id,
    from,
    to,
    from_ms,
    to_ms,
    app_id
  }

  return axios({
    url: `offers/${offerId}/availability`,
    method: 'GET',
    params
  }).then(handleApiErrors)
}
