import {
  all,
  put,
  call,
  takeEvery,
  takeLatest,
  select
} from 'redux-saga/effects'
import moment from 'moment'

import { apiError, showPopup, calendarRefStorage } from '../helpers'
import { captureException } from '../helpers/sentry'
import { fetchSchedulesSaga } from './schedules'
import { fetchBookingsSaga, fetchBookings } from './bookings'
import { toggleDrawer } from './ui'

export const initialState = {
  currentDate: moment.utc(),
  from: null,
  to: null,
  loaded: false,
  loading: true,
  error: false,
  selectedEvent: null,
  selectedView: 'month'
}

export const moduleName = 'calendar'

export const INITIALIZE_CALENDAR_REQUEST = `${moduleName}/INITIALIZE_CALENDAR_REQUEST`
export const INITIALIZE_CALENDAR_SUCCESS = `${moduleName}/INITIALIZE_CALENDAR_SUCCESS`
export const INITIALIZE_CALENDAR_FAIL = `${moduleName}/INITIALIZE_CALENDAR_FAIL`

export const CHANGE_DATE_REQUEST = `${moduleName}/CHANGE_DATE_REQUEST`
export const CHANGE_DATE_SUCCESS = `${moduleName}/CHANGE_DATE_SUCCESS`
export const CHANGE_DATE_FAIL = `${moduleName}/CHANGE_DATE_FAIL`

export const SET_ACTIVE_DATE_REQUEST = `${moduleName}/SET_ACTIVE_DATE_REQUEST`
export const SET_ACTIVE_DATE_SUCCESS = `${moduleName}/SET_ACTIVE_DATE_SUCCESS`
export const SET_ACTIVE_DATE_FAIL = `${moduleName}/SET_ACTIVE_DATE_FAIL`

export const SET_SELECTED_EVENT = `${moduleName}/SET_SELECTED_EVENT`
export const SET_VIEW = `${moduleName}/SET_VIEW`

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case INITIALIZE_CALENDAR_REQUEST:
      return { ...state, loading: true }

    case INITIALIZE_CALENDAR_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        ...payload
      }

    case INITIALIZE_CALENDAR_FAIL:
      return { ...state, loaded: false, loading: false, error: payload }

    case CHANGE_DATE_REQUEST:
      return { ...state, loading: true }

    case CHANGE_DATE_SUCCESS:
      return { ...state, loading: false, error: false, ...payload }

    case SET_ACTIVE_DATE_REQUEST:
      return { ...state, loading: true }

    case SET_ACTIVE_DATE_SUCCESS:
      return { ...state, loading: false, error: false, ...payload }

    case SET_SELECTED_EVENT:
      return { ...state, selectedEvent: payload }

    case SET_VIEW:
      return { ...state, selectedView: payload }

    default: {
      return state
    }
  }
}

export const initializeCalendar = () => ({
  type: INITIALIZE_CALENDAR_REQUEST
})

export const changeDate = (payload) => ({
  type: CHANGE_DATE_REQUEST,
  payload
})

export const setActiveDate = (payload) => ({
  type: SET_ACTIVE_DATE_REQUEST,
  payload
})

export const setSelectedEvent = (id) => ({
  type: SET_SELECTED_EVENT,
  payload: id
})

export const setView = (view) => ({
  type: SET_VIEW,
  payload: view
})

export const initializeCalendarSaga = function*() {
  try {
    const currentDate = moment.utc()

    const from = moment(currentDate)
      .startOf('month')
      .subtract(1, 'week')
    const to = moment(currentDate)
      .endOf('month')
      .add(1, 'week')

    yield all([
      call(fetchSchedulesSaga, { to, from }),
      call(fetchBookingsSaga, { to, from })
    ])

    yield put({
      type: INITIALIZE_CALENDAR_SUCCESS,
      payload: {
        currentDate,
        from,
        to
      }
    })
  } catch (e) {
    if (e instanceof apiError) {
      const { code, message } = e
      yield put({
        type: INITIALIZE_CALENDAR_SUCCESS,
        payload: {
          code,
          message
        }
      })
    } else {
      yield put({
        type: INITIALIZE_CALENDAR_SUCCESS,
        payload: {
          code: 0,
          message: 'unknown error'
        }
      })

      captureException(e)
    }
    yield put(showPopup(e))
  }
}

export const changeDateSaga = function*({ payload }) {
  try {
    const { selectedView, currentDate } = yield select(
      ({ calendar }) => calendar
    )

    let newDate

    if (payload === 'PREV') {
      if (selectedView === 'week') {
        newDate = moment(currentDate).subtract(1, 'week')
      } else if (selectedView === 'day') {
        newDate = moment(currentDate).subtract(1, 'week')
      } else if (selectedView === 'month') {
        newDate = moment(currentDate).subtract(1, 'month')
      }
    } else if (payload === 'NEXT') {
      if (selectedView === 'week') {
        newDate = moment(currentDate).add(1, 'week')
      } else if (selectedView === 'day') {
        newDate = moment(currentDate).add(1, 'week')
      } else if (selectedView === 'month') {
        newDate = moment(currentDate).add(1, 'month')
      }
    } else if (payload === 'TODAY') {
      newDate = moment()
    }

    calendarRefStorage.ref.current.handleNavigate('DATE', newDate.toDate())

    const from = moment(newDate)
      .startOf('month')
      .subtract(1, 'week') // +1 week
    const to = moment(newDate)
      .endOf('month')
      .add(1, 'week')

    if (!moment(currentDate).isSame(newDate, 'month')) {
      yield all([
        call(fetchSchedulesSaga, { to, from }),
        call(fetchBookingsSaga, { to, from })
      ])
    }

    yield put({
      type: CHANGE_DATE_SUCCESS,
      payload: {
        currentDate: newDate,
        from,
        to
      }
    })
  } catch (e) {
    console.error(e)
  }
}

export const setActiveDateSaga = function*({ payload }) {
  try {
    const { selectedView, currentDate } = yield select(
      ({ calendar }) => calendar
    )

    if (selectedView !== 'day') {
      calendarRefStorage.ref.current.props.onView('day')
      yield put(setView('day'))
    }

    const newDate = moment(payload, 'YYYY-MM-DDTHH:mm:ss')

    calendarRefStorage.ref.current.handleNavigate('DATE', newDate.toDate())

    const newPayload = {
      currentDate: newDate
    }

    if (!moment(currentDate).isSame(newDate, 'month')) {
      const from = moment(newDate)
        .startOf('month')
        .subtract(1, 'week') // +1 week
      const to = moment(newDate)
        .endOf('month')
        .add(1, 'week')

      newPayload.from = from
      newPayload.to = to

      yield all([
        call(fetchSchedulesSaga, { to, from }),
        call(fetchBookingsSaga, { to, from })
      ])
    }

    yield put({
      type: SET_ACTIVE_DATE_SUCCESS,
      payload: newPayload
    })
  } catch (e) {
    console.error(e)
  }
}

export const setSelectedEventSaga = function*({ payload }) {
  try {
    const { isDrawerOpened } = yield select(({ ui }) => ui)
    if (payload && isDrawerOpened) {
      yield put(toggleDrawer())
    }
  } catch (e) {
    console.error(e)
    yield put(showPopup(e))
    captureException(e)
  }
}

export const setViewSaga = function*({ payload }) {
  try {
    const { isDrawerOpened } = yield select(({ ui }) => ui)
    if (isDrawerOpened) {
      yield put(toggleDrawer())
    }
    const { id } = yield select(({ profile }) => profile)
    yield call([localStorage, localStorage.setItem], `${id}_view`, payload)
  } catch (e) {
    console.error(e)
    yield put(showPopup(e))
    captureException(e)
  }
}

export const saga = function*() {
  yield all([
    takeEvery(INITIALIZE_CALENDAR_REQUEST, initializeCalendarSaga),
    takeLatest(CHANGE_DATE_REQUEST, changeDateSaga),
    takeLatest(SET_ACTIVE_DATE_REQUEST, setActiveDateSaga),
    takeLatest(SET_SELECTED_EVENT, setSelectedEventSaga),
    takeLatest(SET_VIEW, setViewSaga)
  ])
}
