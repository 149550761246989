import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { fetchAuth } from '../../ducks/auth'

import CardForm from '../AuthPage/CardForm'
import EmailPasswordForm from '../AuthPage/EmailPasswordForm'

import Button from '../../components/UI/Button'

const StyledToggler = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: 500;

  text-align: center;
  color: #4da1ff;
  cursor: pointer;
  margin-top: 7px;
`

const StyledForm = styled.form`
  margin-top: 36px;
  width: 100%;
`

class AuthForm extends Component {
  constructor(props) {
    super(props)
    const email = localStorage.getItem('email') || ''

    const authByCardStorage = localStorage.getItem('authByCard')

    const authByCard =
      authByCardStorage !== null
        ? authByCardStorage === 'true'
          ? true
          : false
        : false

    this.state = {
      email,
      password: '',
      card: '',
      authByCard
    }
  }

  render() {
    const { t, auth } = this.props
    const { loading } = auth

    return (
      <StyledForm onSubmit={this.handleAuth}>
        {this.renderForm()}
        <Button size="big" disabled={loading} type="submit">
          {t('auth:login')}
        </Button>
        {this.renderFormTypeToggler()}
      </StyledForm>
    )
  }

  handleAuth = (ev) => {
    ev.preventDefault()
    this.props.fetchAuth(this.state)
  }

  handleFormChange = (name) => (ev) => {
    ev.preventDefault()
    const { value } = ev.target

    this.setState({
      [name]: value
    })
  }

  toggleFormType = () => {
    const { authByCard } = this.state
    localStorage.setItem('authByCard', !authByCard)
    this.setState(({ authByCard }) => ({ authByCard: !authByCard }))
  }

  renderFormTypeToggler = () => {
    const { authByCard } = this.state
    const { t } = this.props

    const togglerText = authByCard ? t('auth:byEmail') : t('auth:byCard')

    return (
      <StyledToggler onClick={this.toggleFormType}>{togglerText}</StyledToggler>
    )
  }

  renderForm = () => {
    const { email, password, card, authByCard } = this.state

    if (authByCard) {
      return <CardForm handleFormChange={this.handleFormChange} card={card} />
    } else {
      return (
        <EmailPasswordForm
          handleFormChange={this.handleFormChange}
          email={email}
          password={password}
        />
      )
    }
  }
}

export default compose(
  connect(
    ({ auth }) => ({ auth }),
    {
      fetchAuth
    }
  ),
  withTranslation()
)(AuthForm)
