import React from 'react'
import styled, { css } from 'styled-components'

const StyledButton = styled.button`
  margin-top: ${(props) => props.marTop}px;
  margin-bottom: ${(props) => props.marBot}px;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: 500;
  border: 1px solid rgba(77, 161, 255, 0.7);
  border-radius: 4px;

  padding: 7px 7px;

  ${(props) =>
    props.stretch &&
    css`
      flex: 1;
    `}

  ${(props) =>
    props.minWidth &&
    css`
      min-width: 125px;
    `}

  transition: opacity 0.2s;
  outline: none;

  ${(props) =>
    props.outline
      ? css`
          background-color: #ffffff;
          color: #4da1ff;
        `
      : css`
          background-color: #4da1ff;
          color: #ffffff;
        `}

  ${(props) =>
    props.size === 'big' &&
    css`
      font-size: 14px;
      padding: 14px;
      width: 100%;
      min-width: auto;
    `}

  :hover {
    opacity: 0.8;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7 !important;
    `}
`

export default ({
  children,
  size = 'small',
  outline = false,
  marBot = 10,
  marTop = 10,
  minWidth = true,
  stretch = false,
  ...inputProps
}) => {
  return (
    <StyledButton
      marTop={marTop}
      marBot={marBot}
      size={size}
      outline={outline}
      minWidth={minWidth}
      stretch={stretch}
      {...inputProps}
    >
      {children}
    </StyledButton>
  )
}
