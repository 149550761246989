import {
  all,
  put,
  call,
  takeEvery,
  takeLatest,
  select,
  delay
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import moment from 'moment'

import {
  getClients,
  getOffers,
  getAvailability,
  makeBooking as makeBookingRequest
} from '../api'
import { apiError, showPopup } from '../helpers'
import { captureException } from '../helpers/sentry'
import { logout, LOGOUT } from './auth'
import { fetchSchedulesSaga } from './bookingSchedules'
import { fetchBookingsSaga } from './bookings'
import { setSelectedEvent } from './calendar'

export const initialState = {
  clients: {
    entities: [],
    selectedClient: {
      first_name: '',
      last_name: '',
      second_name: '',
      id: null
    },
    clientId: null,
    clientSearchName: '',
    loading: false,
    error: false
  },
  offers: {
    entities: [],
    offerId: null,
    offerSearchString: '',
    loading: false,
    error: false
  },
  availability: {
    entities: [],
    loading: false,
    error: false
  },
  bookingMonth: new Date(),
  bookingDate: new Date(),
  bookingTime: null,
  isRebooking: false,
  loading: false,
  initialized: false,
  modal: {
    isOpen: false,
    x: 0,
    y: 0
  },
  error: null
}

export const moduleName = 'booking'

export const INITIALIZE_DATA_REQUEST = `${moduleName}/INITIALIZE_DATA_REQUEST`
export const INITIALIZE_DATA_SUCCESS = `${moduleName}/INITIALIZE_DATA_SUCCESS`

export const FETCH_CLIENTS_REQUEST = `${moduleName}/FETCH_CLIENTS_REQUEST`
export const FETCH_CLIENTS_SUCCESS = `${moduleName}/FETCH_CLIENTS_SUCCESS`
export const FETCH_CLIENTS_FAIL = `${moduleName}/FETCH_CLIENTS_FAIL`
export const SET_SELECTED_CLIENT = `${moduleName}/SET_SELECTED_CLIENT`

export const FETCH_OFFERS_REQUEST = `${moduleName}/FETCH_OFFERS_REQUEST`
export const FETCH_OFFERS_SUCCESS = `${moduleName}/FETCH_OFFERS_SUCCESS`
export const FETCH_OFFERS_FAIL = `${moduleName}/FETCH_OFFERS_FAIL`
export const SET_SELECTED_OFFER = `${moduleName}/SET_SELECTED_OFFER`

export const FETCH_AVAILABILITY_REQUEST = `${moduleName}/FETCH_AVAILABILITY_REQUEST`
export const FETCH_AVAILABILITY_SUCCESS = `${moduleName}/FETCH_AVAILABILITY_SUCCESS`
export const FETCH_AVAILABILITY_FAIL = `${moduleName}/FETCH_AVAILABILITY_FAIL`

export const MAKE_BOOKING_REQUEST = `${moduleName}/MAKE_BOOKING_REQUEST`
export const MAKE_BOOKING_SUCCESS = `${moduleName}/MAKE_BOOKING_SUCCESS`
export const MAKE_BOOKING_FAIL = `${moduleName}/MAKE_BOOKING_FAIL`

export const CHANGE_CALENDAR_MONTH_REQUEST = `${moduleName}/CHANGE_CALENDAR_MONTH_REQUEST`
export const CHANGE_CALENDAR_MONTH_SUCCESS = `${moduleName}/CHANGE_CALENDAR_MONTH_SUCCESS`
export const CHANGE_CALENDAR_MONTH_FAIL = `${moduleName}/CHANGE_CALENDAR_MONTH_FAIL`

export const SET_BOOKING_DATE = `${moduleName}/SET_BOOKING_DATE`
export const SET_BOOKING_TIME = `${moduleName}/SET_BOOKING_TIME`
export const SET_OFFER_FILTER = `${moduleName}/SET_OFFER_FILTER`

export const TOGGLE_MODAL_REQUEST = `${moduleName}/TOGGLE_MODAL_REQUEST`
export const TOGGLE_MODAL_SUCCESS = `${moduleName}/TOGGLE_MODAL_SUCCESS`

export const REBOOK_EVENT_REQUEST = `${moduleName}/REBOOK_EVENT_REQUEST`
export const REBOOK_EVENT_SUCCESS = `${moduleName}/REBOOK_EVENT_SUCCESS`

export const CLEAR_STORE = `${moduleName}/CLEAR_STORE`

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_CLIENTS_REQUEST: {
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: true,
          clientSearchName: payload
        }
      }
    }

    case FETCH_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: false,
          error: false,
          entities: payload
        }
      }
    }

    case FETCH_CLIENTS_FAIL: {
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: false,
          error: payload
        }
      }
    }

    case SET_SELECTED_CLIENT:
      return {
        ...state,
        clients: {
          ...state.clients,
          clientId: payload.id,
          selectedClient: payload
        }
      }

    case FETCH_OFFERS_REQUEST:
      return {
        ...state,
        offers: {
          ...state.offers,
          loading: true
        }
      }

    case FETCH_OFFERS_SUCCESS:
      return {
        ...state,
        offers: {
          ...state.offers,
          loading: false,
          error: false,
          entities: payload
        }
      }

    case FETCH_OFFERS_FAIL:
      return {
        ...state,
        offers: {
          ...state.offers,
          loading: false,
          error: payload
        }
      }

    case SET_SELECTED_OFFER:
      return {
        ...state,
        offers: {
          ...state.offers,
          offerId: payload
        }
      }

    case FETCH_AVAILABILITY_REQUEST:
      return {
        ...state,
        availability: {
          ...state.availability,
          loading: true,
          error: false
        }
      }

    case FETCH_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: {
          entities: payload.entities,
          error: false,
          loading: false
        },
        bookingTime: payload.bookingTime
      }

    case FETCH_AVAILABILITY_FAIL:
      return {
        ...state,
        availability: {
          ...state.availability,
          loading: false,
          error: payload
        }
      }

    case SET_BOOKING_DATE:
      return {
        ...state,
        bookingDate: payload
      }

    case SET_BOOKING_TIME:
      return {
        ...state,
        bookingTime: payload
      }

    case MAKE_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }

    case MAKE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      }

    case SET_OFFER_FILTER:
      return {
        ...state,
        offers: {
          ...state.offers,
          offerSearchString: payload
        }
      }

    case TOGGLE_MODAL_SUCCESS:
      return {
        ...state,
        ...payload
      }

    case REBOOK_EVENT_REQUEST:
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: true
        }
      }

    case REBOOK_EVENT_SUCCESS:
      return {
        ...state,
        clients: {
          ...state.clients,
          clientId: payload.clientId,
          selectedClient: payload.client,
          entities: payload.entities
        },
        offers: {
          ...state.offers,
          offerId: payload.offerId,
          offerSearchString: payload.offerName
        },
        isRebooking: true,
        bookingDate: payload.bookingDate,
        bookingTime: payload.bookingTime
      }

    case CHANGE_CALENDAR_MONTH_REQUEST:
      return {
        ...state,
        bookingMonth: payload,
        loading: true
      }
    case CHANGE_CALENDAR_MONTH_SUCCESS:
      return {
        ...state,
        // bookingMonth: payload,
        loading: false
      }

    case CLEAR_STORE:
    case LOGOUT:
      return {
        ...initialState
      }

    default: {
      return state
    }
  }
}

export const initializeData = () => ({
  type: INITIALIZE_DATA_REQUEST
})

export const rebookEvent = (
  clientId,
  client,
  offerId,
  offerName,
  offerTime
) => ({
  type: REBOOK_EVENT_REQUEST,
  payload: {
    clientId,
    client,
    offerId,
    offerName,
    offerTime
  }
})

export const fetchClients = (name) => ({
  type: FETCH_CLIENTS_REQUEST,
  payload: name
})

export const fetchOffers = (id) => ({
  type: FETCH_OFFERS_REQUEST,
  payload: id
})

export const selectClient = (client) => ({
  type: SET_SELECTED_CLIENT,
  payload: client
})

export const selectOffer = (id) => ({
  type: SET_SELECTED_OFFER,
  payload: id
})

export const fetchAvailability = () => ({
  type: FETCH_AVAILABILITY_REQUEST
})

export const setBookingDate = (date) => ({
  type: SET_BOOKING_DATE,
  payload: date
})

export const setBookingTime = (time) => ({
  type: SET_BOOKING_TIME,
  payload: time
})

export const setOfferFilter = (text) => ({
  type: SET_OFFER_FILTER,
  payload: text
})

export const makeBooking = () => ({
  type: MAKE_BOOKING_REQUEST
})

export const toggleModal = ({
  x = 0,
  y = 0,
  isRebooking = false,
  isOpen = false
}) => ({
  type: TOGGLE_MODAL_REQUEST,
  payload: {
    x,
    y,
    isRebooking,
    isOpen
  }
})

export const clearBookingStore = () => ({
  type: CLEAR_STORE
})

export const changeCalendarMonth = (payload) => ({
  type: CHANGE_CALENDAR_MONTH_REQUEST,
  payload
})

export const initializeDataSaga = function*() {
  try {
    const { isRebooking, bookingDate } = yield select(({ booking }) => booking)
    yield put(changeCalendarMonth(bookingDate))
    if (!isRebooking) {
      yield put(fetchClients('', false))
      yield put(fetchOffers(null, false))
    }
  } catch (e) {
    console.error(e)
  }
}

export const rebookEventSaga = function*({ payload }) {
  try {
    const { selectedEvent } = yield select(({ calendar }) => calendar)
    const { bookingDate } = yield select(({ booking }) => booking)
    const { clientId, client, offerTime } = payload

    const newBookingDate = moment(bookingDate, 'YYYY-MM-DDTHH:mm:ss')
      .add(1, 'day')
      .toDate()

    const newBookingTime = moment(offerTime, 'YYYY-MM-DDTHH:mm:ss')

    const clients = [
      {
        ...client
      }
    ]

    if (selectedEvent) {
      yield put(setSelectedEvent(null))
    }

    yield put({
      type: REBOOK_EVENT_SUCCESS,
      payload: {
        ...payload,
        entities: clients,
        bookingDate: newBookingDate,
        bookingTime: newBookingTime
      }
    })

    yield put(fetchOffers(clientId, false))
    yield put(changeCalendarMonth(bookingDate))
    yield put(fetchAvailability())
  } catch (e) {
    console.error(e)
  }
}

export const fetchClientsSaga = function*({ payload }) {
  yield delay(300)
  try {
    const response = yield call(getClients, { name: payload })

    yield put({
      type: FETCH_CLIENTS_SUCCESS,
      payload: response
    })
  } catch (e) {
    if (e instanceof apiError) {
      const { code, message } = e
      yield put({
        type: FETCH_CLIENTS_FAIL,
        payload: {
          code,
          message
        }
      })
      if (code === 4) {
        yield put(logout())
      }
    } else {
      yield put({
        type: FETCH_CLIENTS_FAIL,
        payload: {
          code: 0,
          message: 'unknown error'
        }
      })

      captureException(e)
    }
    yield put(showPopup(e))
  }
}

export const selectClientSaga = function*() {
  try {
    yield put(fetchOffers())
    yield put(fetchAvailability())
  } catch (e) {
    console.error(e)
  }
}

export const fetchOffersSaga = function*({ payload }) {
  // !TODO Filter like in component
  try {
    const response = yield call(getOffers, { card_id: payload })

    yield put({
      type: FETCH_OFFERS_SUCCESS,
      payload: response
    })
  } catch (e) {
    if (e instanceof apiError) {
      const { code, message } = e
      yield put({
        type: FETCH_OFFERS_FAIL,
        payload: {
          code,
          message
        }
      })
      if (code === 4) {
        yield put(logout())
      }
    } else {
      yield put({
        type: FETCH_OFFERS_FAIL,
        payload: {
          code: 0,
          message: 'unknown error'
        }
      })

      captureException(e)
    }
    yield put(showPopup(e))
  }
}

export const selectOfferSaga = function*() {
  try {
    yield put(fetchAvailability())
  } catch (e) {
    console.log(e)
  }
}

export const fetchAvailabilitySaga = function*() {
  try {
    const { clients, offers, bookingDate, bookingTime } = yield select(
      ({ booking }) => booking
    )
    const { offerId } = offers
    if (offerId && clients.clientId) {
      const from = moment(bookingDate).format('DDMMYYYY')
      const response = yield call(getAvailability, {
        offerId,
        card_id: clients.clientId,
        from,
        to: from
      })

      let newBookingTime = null

      if (bookingTime && response.length !== 0) {
        const bookingDateMoment = moment(bookingDate)
        const bookingTimeMoment = moment(bookingTime)
          .set('date', bookingDateMoment.get('date'))
          .set('year', bookingDateMoment.get('year'))
          .set('month', bookingDateMoment.get('month'))

        const searchableString = bookingTimeMoment.format('HH:mm:00')
        const newTime = response.find((time) => time.includes(searchableString))
        if (newTime) {
          newBookingTime = newTime
        } else {
          let time = null
          let bestPossibleDifference = 86400000
          for (let i = 0; i < response.length; i++) {
            const item = response[i]
            const timeInMs = Math.abs(+new Date(item) - +bookingTimeMoment)

            if (timeInMs < bestPossibleDifference) {
              time = item
              bestPossibleDifference = timeInMs
            }
          }

          newBookingTime = time
        }
      }

      yield put({
        type: FETCH_AVAILABILITY_SUCCESS,
        payload: {
          entities: response,
          bookingTime: newBookingTime
        }
      })
    } else {
      yield put({
        type: FETCH_AVAILABILITY_SUCCESS,
        payload: {
          entities: [],
          bookingTime
        }
      })
    }
  } catch (e) {
    if (e instanceof apiError) {
      const { code, message } = e
      yield put({
        type: FETCH_AVAILABILITY_FAIL,
        payload: {
          code,
          message
        }
      })
      if (code === 4) {
        yield put(logout())
      }
    } else {
      yield put({
        type: FETCH_AVAILABILITY_FAIL,
        payload: {
          code: 0,
          message: 'unknown error'
        }
      })

      captureException(e)
    }
    yield put(showPopup(e))
  }
}

export const setBookingDateSaga = function*() {
  try {
    yield put(fetchAvailability())
  } catch (e) {
    console.error(e)
  }
}

export const makeBookingSaga = function*() {
  try {
    const { clients, offers, bookingTime } = yield select(
      ({ booking }) => booking
    )
    const { from, to } = yield select(({ calendar }) => calendar)
    const { outlet } = yield select(({ profile }) => profile)

    const card_id = clients.clientId
    const offer_id = offers.offerId
    const outlet_id = outlet.id
    const date = moment(bookingTime).format('DD.MM.YYYY HH:mm')

    yield call(makeBookingRequest, {
      card_id,
      offer_id,
      outlet_id,
      from: date
    })

    // TODO This is really bad
    yield call(fetchBookingsSaga, { from, to })

    yield put({
      type: MAKE_BOOKING_SUCCESS
    })
    yield put(clearBookingStore())
  } catch (e) {
    console.error(e)
    if (e instanceof apiError) {
      const { code, message } = e
      yield put({
        type: FETCH_AVAILABILITY_FAIL,
        payload: {
          code,
          message
        }
      })
      if (code === 4) {
        yield put(logout())
      }
    } else {
      yield put({
        type: FETCH_AVAILABILITY_FAIL,
        payload: {
          code: 0,
          message: 'unknown error'
        }
      })

      captureException(e)
    }
    yield put(showPopup(e))
  }
}

let modalOpenTime = +new Date()

export const toggleModalSaga = function*({ payload }) {
  try {
    if (+new Date() - modalOpenTime < 2000) {
      return null
    }

    modalOpenTime = +new Date()

    const { x, y, isRebooking, isOpen } = payload

    yield put({
      type: TOGGLE_MODAL_SUCCESS,
      payload: {
        isRebooking,
        modal: {
          x,
          y,
          isOpen
        }
      }
    })

    if (isOpen) {
      yield put(initializeData())
    } else {
      yield put(clearBookingStore())
    }
  } catch (e) {
    console.error(e)
  }
}

export const changeCalendarMonthSaga = function*() {
  try {
    const { bookingMonth } = yield select(({ booking }) => booking)

    const from = moment(bookingMonth).startOf('month')
    const to = moment(bookingMonth).endOf('month')

    yield call(fetchSchedulesSaga, { to, from })
    yield put({
      type: CHANGE_CALENDAR_MONTH_SUCCESS
    })
  } catch (e) {
    console.error(e)
  }
}

export const saga = function*() {
  yield all([
    takeEvery(INITIALIZE_DATA_REQUEST, initializeDataSaga),
    takeLatest(FETCH_CLIENTS_REQUEST, fetchClientsSaga),
    takeLatest(FETCH_OFFERS_REQUEST, fetchOffersSaga),
    takeLatest(SET_SELECTED_CLIENT, selectClientSaga),
    takeLatest(SET_SELECTED_OFFER, selectOfferSaga),
    takeLatest(FETCH_AVAILABILITY_REQUEST, fetchAvailabilitySaga),
    takeLatest(SET_BOOKING_DATE, setBookingDateSaga),
    takeLatest(MAKE_BOOKING_REQUEST, makeBookingSaga),
    takeLatest(REBOOK_EVENT_REQUEST, rebookEventSaga),
    takeLatest(CHANGE_CALENDAR_MONTH_REQUEST, changeCalendarMonthSaga),
    takeLatest(TOGGLE_MODAL_REQUEST, toggleModalSaga)
  ])
}
