export default {
  auth: {
    byEmail: 'การรับรองผ่านทางอีเมลล์และพาสเวิด',
    byCard: 'การรับรองผ่านทางการ์ด',
    login: 'ล็อคอิน',
    cardNumber: 'หมายเลขการ์ด',
    email: 'อีเมลล์',
    password: 'พาสเวิด',
    logout: 'ออกจากระบบ'
  },
  calendar: {
    today: 'วันนี้',
    day: 'วัน',
    week: 'สัปดาห์',
    month: 'เดือน',
    controlData: (month, year) => `${month} ของ ${year}`,
    showMore: (count) => `บวก ${count} เพิ่ม`
  },
  booking: {
    loading: 'กำลังดาวโหลด',
    bookNow: 'ยืนยันการจองทันที',
    details: 'รายละเอียดการจอง',
    noEvent: 'ไม่มีการเลือกอีเว้น',
    minutesShort: 'นาที',
    status: 'สถานะ',
    markCompleted: 'มาร์ค สำเร็จ',
    rebook: 'บุ๊คกิ้งอีกครั้ง',
    time: 'เวลา',
    chooseTime: 'เลือกเวลาสำหรับการจอง',
    noTimeAvail: 'ไม่มีเวลาว่างเหลืออยู่',
    client: 'ลูกค้า',
    noClientSelected: 'ไม่ได้ทำการเลือกลูกค้า',
    noClientFound: 'ไม่พบลูกค้าท่านนี้',
    offers: 'ข้อเสนอ',
    noOffersSelected: 'ไม่ได้ทำการเลือกข้อเสนอ',
    noOffersFound: 'ไม่พบข้อเสนอ'
  },
  schedule: {
    upcomingToday: 'ที่จะมาถึงวันนี้',
    noUpcoming: 'ไม่มีการจองสำหรับวันนี้'
  },
  statuses: {
    COMPLETE: 'การจองสำเร็จ',
    INCOMPLETE: 'ไม่สำเร็จ',
    IN_PROCESS: 'อยู่ในระหว่างการดำเนินการ',
    CONFIRMED: 'ได้รับการยืนยัน',
    NO_SHOW: 'ไม่ปรากฎ',
    CANCEL: 'ยกเลิก',
    DONE: 'สำเร็จ',
    UNDEFINED: 'ไม่ได้กำหนด',
    TENTATIVE: 'ชั่วคราว'
  }
}
