import styled from 'styled-components'

export default styled.div`
  background-color: #f4f8f9;
  height: 100vh;
  /* max-height: 100vh; */
  width: 100%;
  overflow-x: hidden;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;

  @media (max-height: 800px) {
    height: 800px;
    overflow-y: auto;
  }
`
