import { axios, handleApiErrors, getToken } from '../helpers'

export default ({ name = '' }) => {
  const token = getToken()
  const app_id = window.appConfig.app_id

  const params = { session_id: token, app_id }

  const composedURL = name !== '' ? `client/${name}` : 'clients'

  return axios({
    url: composedURL,
    method: 'GET',
    params
  }).then(handleApiErrors)
}
