import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import moment from 'moment'

const StyledMonthHeader = styled.div`
  margin: 14px 0;

  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #bdbdbd;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-transform: uppercase;

  ${(props) =>
    props.active &&
    css`
      color: #4192ec;
    `}
`

const MonthHeader = ({ date, ui }) => {
  const { isMobile } = ui
  const momentDate = moment(date)

  const dayName = isMobile
    ? momentDate.format('dddd')[0]
    : momentDate.format('dddd')
  const isActive = moment().day() === momentDate.day()

  return (
    <StyledMonthHeader active={isActive}>
      <span>{dayName}</span>
    </StyledMonthHeader>
  )
}

export default connect(({ ui }) => ({ ui }))(MonthHeader)
