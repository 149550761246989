import { all, put, takeLatest, select } from 'redux-saga/effects'

const initialState = {
  isMobile: false,
  mobileBreakpoint: 930,
  isDrawerOpened: false
}

export const moduleName = 'ui'
export const UPDATE_ELEMENT_SIZE_REQUEST = `${moduleName}/UPDATE_ELEMENT_SIZE_REQUEST`
export const UPDATE_ELEMENT_SIZE_SUCCESS = `${moduleName}/UPDATE_ELEMENT_SIZE_SUCCESS`
export const SET_MOBILE_STATE = `${moduleName}/SET_MOBILE_STATE`
export const TOGGLE_DRAWER = `${moduleName}/TOGGLE_DRAWER`

export const TOGGLE_MODAL = `${moduleName}/TOGGLE_MODAL`

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case UPDATE_ELEMENT_SIZE_SUCCESS:
      return { ...state, sizes: { ...state.sizes, ...payload } }

    case SET_MOBILE_STATE:
      return { ...state, isMobile: payload }

    case TOGGLE_DRAWER:
      return { ...state, isDrawerOpened: !state.isDrawerOpened }

    default:
      return state
  }
}

export function updateElementSize(element, size) {
  return {
    type: UPDATE_ELEMENT_SIZE_REQUEST,
    payload: { element, size }
  }
}

export const toggleDrawer = () => ({
  type: TOGGLE_DRAWER
})

export const setMobileState = (state) => ({
  type: SET_MOBILE_STATE,
  payload: state
})

export const updateElementSizeSaga = function*({ payload }) {
  try {
    // Decreases time of updates while dragging
    // yield delay(20)
    const { size } = payload
    const { width } = size
    const { isMobile, mobileBreakpoint } = yield select(({ ui }) => ui)
    if (width <= mobileBreakpoint) {
      if (isMobile) {
      } else {
        yield put(setMobileState(true))
      }
    } else {
      if (isMobile) {
        yield put(setMobileState(false))
      } else {
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export const saga = function*() {
  yield all([takeLatest(UPDATE_ELEMENT_SIZE_REQUEST, updateElementSizeSaga)])
}
