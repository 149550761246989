import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import moment from 'moment'

import EventsList from './EventsList'

const StyledWrapper = styled.div`
  margin: 0 28px 33px 28px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const StyledTitle = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #bdbdbd;
  text-transform: uppercase;

  padding-bottom: 5px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 19px;
`

const StyledNoSchedule = styled.div`
  color: #2e384d;
  font-size: 15px;
  font-weight: 500;

  padding: 10px 15px;
`

class TodaysSchedule extends Component {
  render() {
    const { bookings, t } = this.props
    const { todaysBooking } = bookings
    const currentDateMoment = moment()
    const upcomingEvents = todaysBooking.filter((event) => {
      const resultDate = moment(event.treatmentStart, 'YYYY-MM-DDTHH:mm:ss')

      if (currentDateMoment.isBefore(resultDate)) {
        return true
      } else {
        return false
      }
    })

    if (!upcomingEvents) {
      return (
        <StyledWrapper>
          <StyledTitle>{t('schedule:upcomingToday')}</StyledTitle>
          <StyledNoSchedule>{t('schedule:noUpcoming')}</StyledNoSchedule>
        </StyledWrapper>
      )
    }

    return (
      <StyledWrapper>
        <StyledTitle>{t('schedule:upcomingToday')}</StyledTitle>
        <EventsList
          noEventsText={t('schedule:noUpcoming')}
          events={upcomingEvents}
        />
      </StyledWrapper>
    )
  }
}

export default compose(
  connect(({ calendar, bookings }) => ({ calendar, bookings })),
  withTranslation()
)(TodaysSchedule)
