import {
  all,
  put,
  call,
  takeEvery,
  takeLatest,
  select
} from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { apiError, getToken, showPopup } from '../helpers'
import { captureException } from '../helpers/sentry'
import { authPerson } from '../api'

import { setView } from './calendar'
import { fetchProfileSaga } from './profile'
import { START_BACKGROUND_SYNC, STOP_BACKGROUND_SYNC } from './bookings'
import { initLocale } from './localization'

export const initialState = {
  token: '',
  authorized: null,
  loading: false,
  initialized: false,
  error: null
}

export const moduleName = 'auth'

export const FETCH_AUTH_REQUEST = `${moduleName}/FETCH_AUTH_REQUEST`
export const FETCH_AUTH_SUCCESS = `${moduleName}/FETCH_AUTH_SUCCESS`
export const FETCH_AUTH_FAIL = `${moduleName}/FETCH_AUTH_FAIL`

export const CHECK_AUTH_REQUEST = `${moduleName}/CHECK_AUTH_REQUEST`
export const CHECK_AUTH_SUCCESS = `${moduleName}/CHECK_AUTH_SUCCESS`
export const LOGOUT = `${moduleName}/LOGOUT`

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_AUTH_REQUEST:
      return { ...state, loading: true, error: null }
    case FETCH_AUTH_SUCCESS:
      return { ...state, loading: false, error: null, ...payload }
    case FETCH_AUTH_FAIL:
      return { ...state, loading: false, error: payload }

    case CHECK_AUTH_SUCCESS:
      return { ...state, ...payload }

    case LOGOUT:
      return {
        ...initialState,
        initialized: true
      }

    default: {
      return state
    }
  }
}

export const fetchAuth = (payload) => ({
  type: FETCH_AUTH_REQUEST,
  payload
})

export const checkAuth = () => ({
  type: CHECK_AUTH_REQUEST
})

export const logout = () => ({
  type: LOGOUT
})

export const fetchAuthSaga = function*({ payload }) {
  try {
    const token = yield call(authPerson, payload)

    const { email, authByCard } = payload

    if (!authByCard) {
      yield call([localStorage, localStorage.setItem], 'email', email)
    }
    yield call([localStorage, localStorage.setItem], 'token', token)

    yield put({
      type: FETCH_AUTH_SUCCESS,
      payload: {
        authorized: true,
        initialized: true,
        token
      }
    })

    try {
      yield call(afterAuthSaga)
    } catch (e) {
      return e
    }

    yield put(push('/dashboard'))
  } catch (e) {
    if (e instanceof apiError) {
      const { code, message } = e
      yield put({
        type: FETCH_AUTH_FAIL,
        payload: {
          code,
          message
        }
      })
      if (code === 4) {
        yield put(logout())
      }
    } else {
      yield put({
        type: FETCH_AUTH_FAIL,
        payload: {
          code: 0,
          message: 'unknown error'
        }
      })

      captureException(e)
    }
    yield put(showPopup(e))
  }
}

export const checkAuthSaga = function*() {
  try {
    const token = yield call(getToken)
    if (!token) {
      yield put(push('/'))

      return yield put({
        type: CHECK_AUTH_SUCCESS,
        payload: {
          initialized: true,
          authorized: false
        }
      })
    }

    try {
      yield call(afterAuthSaga)
    } catch (e) {
      return e
    }

    return yield put({
      type: CHECK_AUTH_SUCCESS,
      payload: {
        initialized: true,
        authorized: true,
        token
      }
    })
  } catch (e) {
    console.error(e)
  }
}

export const afterAuthSaga = function*() {
  try {
    yield call(fetchProfileSaga)

    const { id } = yield select(({ profile }) => profile)

    let selectedView = yield call(
      [localStorage, localStorage.getItem],
      `${id}_view`
    )

    selectedView = selectedView || 'week'
    yield put(setView(selectedView))
    yield put(initLocale())
    yield put({
      type: START_BACKGROUND_SYNC
    })
  } catch (e) {
    console.error(e)
    yield put(logout())
    throw e
  }
}

export const logoutSaga = function*() {
  yield call([localStorage, localStorage.removeItem], 'token')
  yield put({
    type: STOP_BACKGROUND_SYNC
  })
  yield put(push('/'))
}

export const saga = function*() {
  yield all([
    takeEvery(FETCH_AUTH_REQUEST, fetchAuthSaga),
    takeEvery(CHECK_AUTH_REQUEST, checkAuthSaga),
    takeLatest(LOGOUT, logoutSaga)
  ])
}
