import React from 'react'
import styled from 'styled-components'

const EventComponent = (props) => {
  return (
    <div
      style={{
        flex: 1,
        fontSize: 10,
        display: 'flex',
        alignItems: 'flex-start'
        // color: '#39424d'
      }}
    >
      <span>
        {props.event.treatmentName}
        {props.event.facility && ` - ${props.event.facility}`}
      </span>
    </div>
  )
}

export default EventComponent
