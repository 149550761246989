export default {
  auth: {
    byEmail: 'Verifikasi dengan email atau password',
    byCard: 'Verifikasi dengan kartu',
    login: 'Masuk',
    cardNumber: 'Nomor Kartu',
    email: 'Email',
    password: 'Password',
    logout: 'Keluar'
  },
  calendar: {
    today: 'Hari ini',
    day: 'Hari',
    week: 'Minggu',
    month: 'Bulan',
    controlData: (month, year) => `${month} of ${year}`,
    showMore: (count) => `Tambah ${count} lebih`
  },
  booking: {
    loading: 'Proses',
    bookNow: 'Pesan Sekarang',
    details: 'Detail pemesanan',
    noEvent: 'Tidak ada yang dipilih',
    minutesShort: 'Menit',
    status: 'Status',
    markCompleted: 'Menandai selesai',
    rebook: 'Pesan ulang',
    time: 'Waktu',
    chooseTime: 'Pilih waktu pemesanan',
    noTimeAvail: 'Waktu pemesanan tidak tersedia',
    client: 'Tamu',
    noClientSelected: 'Tidak ada tamu yang dipilih',
    noClientFound: 'Customer tidak ditemukan',
    offers: 'Penawaran',
    noOffersSelected: 'Tidak ada penawaran yang dipilih',
    noOffersFound: 'Penawaran tidak ditemukan'
  },
  schedule: {
    upcomingToday: 'Yang akan hadir hari ini',
    noUpcoming: 'Tidak ada pemesanan hari ini'
  },
  statuses: {
    COMPLETE: 'Pesan',
    INCOMPLETE: 'Belum lengkap',
    IN_PROCESS: 'Sedang proses',
    CONFIRMED: 'Sudah konfirm',
    NO_SHOW: 'Tidak hadir',
    CANCEL: 'Cancel',
    DONE: 'Selesai',
    UNDEFINED: 'Belum konfirm',
    TENTATIVE: 'Tentative'
  }
}
