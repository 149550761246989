import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import closeSvg from '../../images/icons/close.svg'

import CalendarViewSwitcher from '../../components/CalendarViewSwitcher'

import User from './User'
import TodaysSchedule from './TodaysSchedule'

import { toggleDrawer } from '../../ducks/ui'

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: flex-end;
  transform: translateX(100%);

  /* display: none; */

  opacity: 0;
  transition: opacity 0.2s;

  &.active {
    transform: translateX(0);
    opacity: 1;
  }
`

const StyledContentWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 10010;
  transform: translateX(100%);
  width: 262px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  transition: transform 0.2s;

  &.active {
    transform: translateX(0);
  }
`

const CalendarViewSwitcherWrapper = styled.div`
  margin: 0 24px 28px 19px;
`

const CloseIcon = styled.div`
  position: absolute;
  top: 22px;
  right: 16px;
  height: 13px;
  width: 13px;
  cursor: pointer;

  background: url(${closeSvg}) center center no-repeat;
  background-size: contain;
`

class Drawer extends Component {
  render() {
    const { setActiveView, activeView, toggleDrawer, ui } = this.props
    const { isDrawerOpened } = ui

    return (
      <React.Fragment>
        <StyledWrapper
          onClick={toggleDrawer}
          className={isDrawerOpened && 'active'}
        />
        <StyledContentWrapper className={isDrawerOpened && 'active'}>
          <User />
          <CalendarViewSwitcherWrapper>
            <CalendarViewSwitcher
              activeView={activeView}
              setActiveView={setActiveView}
            />
          </CalendarViewSwitcherWrapper>
          <TodaysSchedule />
          <CloseIcon onClick={toggleDrawer} />
        </StyledContentWrapper>
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    const { isDrawerOpened } = this.props.ui
    if (isDrawerOpened) {
      this.props.toggleDrawer()
    }
  }
}

export default connect(
  ({ ui }) => ({ ui }),
  { toggleDrawer }
)(Drawer)
