import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Input from '../../components/UI/Input'

class EmailPasswordForm extends Component {
  constructor(props) {
    super(props)

    this._emailRef = React.createRef()
    this._passwordRef = React.createRef()
  }

  componentDidMount() {
    const { email } = this.props
    if (email) {
      this._passwordRef.current.focus()
    } else {
      this._emailRef.current.focus()
    }
  }

  render() {
    const { email, password, handleFormChange, t } = this.props

    return (
      <React.Fragment>
        <Input
          innerRef={this._emailRef}
          label={t('auth:email')}
          id="email"
          name="email"
          value={email}
          placeholder={t('auth:email')}
          onChangeHandler={handleFormChange('email')}
        />
        <Input
          innerRef={this._passwordRef}
          type="password"
          label={t('auth:password')}
          id="password"
          name="password"
          value={password}
          placeholder={t('auth:password')}
          onChangeHandler={handleFormChange('password')}
        />
      </React.Fragment>
    )
  }
}

export default withTranslation()(EmailPasswordForm)
