import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'

const StyledMonthEvent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #323c47;

  .dot {
    flex: 0 0 auto;
    width: 6px;
    height: 6px;

    border-radius: 50%;
    background-color: #000;
    background-color: #${(props) => props.bgColor};
    margin-right: 6px;
  }

  .time {
    font-weight: 600;
    margin-right: 4px;
  }

  .event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const MonthEventComponent = ({ event }) => {
  const momentDate = moment(event.treatmentStart, 'YYYY-MM-DDTHH:mm:ss')
  const time = momentDate.format('LT')

  const leftPaddedColor = `000000${event.color}`.slice(-6)

  return (
    <StyledMonthEvent bgColor={leftPaddedColor}>
      <div className="dot" />
      <span className="time">{time}</span>
      <span className="event">{event.treatmentName}</span>
    </StyledMonthEvent>
  )
}

export default connect(({ localization }) => ({ localization }))(
  MonthEventComponent
)
