import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

// import Navbar from '../containers/Navbar'
import { DashboardPage } from '../containers/Pages/Dashboard'
import withAuth from '../containers/HOC/withAuth'

const Routes = () => {
  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <Switch>
        {/* <Route path="/dashboard/booking" component={BookingPage} /> */}
        <Route path="/dashboard/" component={DashboardPage} />
        <Route path="*" render={() => <Redirect to="/dashboard/" />} />
      </Switch>
    </React.Fragment>
  )
}

export default withAuth(Routes)
