import React from 'react'
import { withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: rgba(233, 239, 244, 0.6);
  padding: 7px;
  border-radius: 4px;
`

const StyledItem = styled.div`
  flex: 1 0 auto;
  color: grey;
  padding: 9px 30px;

  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #989898;
  text-align: center;

  border-radius: 4px;
  transition: all 0.2s;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${(props) =>
    props.active &&
    css`
      color: #323c47;
      background-color: #fff;
    `};

  :hover {
    opacity: 0.8;
    cursor: pointer;
  }

  @media (max-width: 930px) {
    padding: 9px 11px;
    flex: 1 1 33%;
  }
`

const CalendarViewSwitcher = ({ activeView, setActiveView, t }) => (
  <Wrapper>
    <StyledItem
      active={activeView === 'day'}
      onClick={() => setActiveView('day')}
    >
      {t('calendar:day')}
    </StyledItem>
    <StyledItem
      active={activeView === 'week'}
      onClick={() => setActiveView('week')}
    >
      {t('calendar:week')}
    </StyledItem>
    <StyledItem
      active={activeView === 'month'}
      onClick={() => setActiveView('month')}
    >
      {t('calendar:month')}
    </StyledItem>
  </Wrapper>
)

export default withTranslation()(CalendarViewSwitcher)
