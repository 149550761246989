import { all } from 'redux-saga/effects'
import { saga as authSaga } from '../ducks/auth'
import { saga as bookingSaga } from '../ducks/booking'
import { saga as bookingsSaga } from '../ducks/bookings'
import { saga as calendarSaga } from '../ducks/calendar'
import { saga as profileSaga } from '../ducks/profile'
import { saga as schedulesSaga } from '../ducks/schedules'
import { saga as bookingSchedulesSaga } from '../ducks/bookingSchedules'
import { saga as uiSaga } from '../ducks/ui'
import { saga as localizationSaga } from '../ducks/localization'
import { saga as checkinSaga } from '../ducks/checkin'
// import { saga as appSaga } from '../ducks/app'

export default function* rootSaga() {
  yield all([
    authSaga(),
    bookingSaga(),
    bookingsSaga(),
    calendarSaga(),
    profileSaga(),
    schedulesSaga(),
    bookingSchedulesSaga(),
    uiSaga(),
    localizationSaga(),
    checkinSaga()
    // appSaga(),
  ])
}
