import { axios, handleApiErrors, getToken } from '../helpers'

export default ({ from = '11032019', to = '17032019' }) => {
  const token = getToken()
  const app_id = window.appConfig.app_id

  const params = { session_id: token, from, to, app_id }

  return axios({
    url: 'schedules',
    method: 'GET',
    params
  }).then(handleApiErrors)
}
