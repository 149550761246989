import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'reset-css'
import 'bootstrap/dist/css/bootstrap.css'
import initSentry from './helpers/sentry'
import './helpers/i18n'
import globalCss from './helpers/globalCss.css'
import 'moment/min/locales'

initSentry()

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
