import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled, { css } from 'styled-components'

import { setSelectedEvent } from '../../ducks/calendar'

const StyledEventList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  overflow-y: scroll;
`

const StyledItem = styled.div`
  margin-bottom: 27px;

  color: #2e384d;
  font-size: 15px;
  font-weight: 500;

  box-shadow: 0px 5px 10px 4px rgba(0, 0, 0, 0);

  cursor: pointer;
  transition: box-shadow 0.2s;

  ${(props) =>
    props.active
      ? css`
          box-shadow: 0px 5px 10px 4px rgba(46, 91, 255, 0.07);
        `
      : css`
          :hover {
            box-shadow: 0px 5px 10px 4px rgba(46, 91, 255, 0.07);
          }
        `}
`

const StyledTicketData = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: #323c47;

  margin-bottom: 7px;

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `}
`

const EventList = ({ calendar, events, setSelectedEvent, noEventsText }) => {
  return (
    <StyledEventList>
      {events.length === 0 && (
        <StyledTicketData bold>{noEventsText}</StyledTicketData>
      )}
      {events.map(({ id, client, treatmentName, treatmentStart, facility }) => {
        let clientName = null
        if (client) {
          clientName = [client.lastName, client.firstName, client.secondName]
            .filter((s) => s)
            .join(' ')
        }

        return (
          <StyledItem
            active={calendar.selectedEvent === id}
            onClick={() => setSelectedEvent(id)}
            key={id}
          >
            <StyledTicketData bold>
              {moment(treatmentStart).format('LT')}
            </StyledTicketData>
            {clientName && <StyledTicketData>{clientName}</StyledTicketData>}
            <StyledTicketData>{treatmentName}</StyledTicketData>
            <StyledTicketData>{facility}</StyledTicketData>
          </StyledItem>
        )
      })}
    </StyledEventList>
  )
}

export default connect(
  ({ calendar }) => ({ calendar }),
  { setSelectedEvent }
)(EventList)
