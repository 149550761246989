export default {
  auth: {
    byEmail: '電郵及密碼認證',
    byCard: '卡號認證',
    login: '登入',
    cardNumber: '卡號',
    email: '電郵',
    password: '密碼',
    logout: '登出'
  },
  calendar: {
    today: '今天',
    day: '日',
    week: '週',
    month: '月',
    controlData: (month, year) => `${month} ${year}`,
    showMore: (count) => `加多${count}個`
  },
  booking: {
    loading: '載入中',
    bookNow: '立即預訂',
    details: '預訂詳情',
    noEvent: '沒有選擇活動',
    minutesShort: '分鐘',
    status: '狀態',
    markCompleted: '已完成',
    rebook: '重新預訂',
    time: '時間',
    chooseTime: '選擇預訂時間',
    noTimeAvail: '沒有時間能夠預訂',
    client: '客人',
    noClientSelected: '沒有選擇客人',
    noClientFound: '找不到客人',
    offers: '優惠',
    noOffersSelected: '沒有選擇優惠',
    noOffersFound: '找不到優惠'
  },
  schedule: {
    upcomingToday: '今天的預訂',
    noUpcoming: '今天沒有預訂'
  },
  statuses: {
    COMPLETE: '預訂',
    INCOMPLETE: '未完成',
    IN_PROCESS: '進行中',
    CONFIRMED: '已確認',
    NO_SHOW: '缺席',
    CANCEL: '取消',
    DONE: '已完成',
    UNDEFINED: '未定義',
    TENTATIVE: '暫定'
  }
}
