import React from 'react'
import styled, { css } from 'styled-components'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 13px;
`

const StyledLabel = styled.label`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #bdbdbd;

  text-transform: uppercase;
  margin-bottom: 7px;
`

const StyledInput = styled.input`
  box-sizing: border-box;

  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.16px;
  color: #323c47;

  transition: all 0.2s;

  border-radius: 4px;
  border: 1px solid #e9eff4;

  padding: 10px 13px;

  outline: none;

  ::placeholder {
    color: #9a9fa4;
  }

  /* Filled */
  ${(props) =>
    props.value !== '' &&
    css`
      border-color: #d3dee6;
    `}

  /* Disabled */
  :disabled {
    border-color: #d2d2d2;
    color: #d2d2d2;

    ::placeholder {
      color: #d2d2d2;
    }
  }

  /* Error */
  ${(props) =>
    props.error
      ? css`
          border-color: #b9715c !important;
          color: #b9715c !important;
          box-shadow: 0 0 0 0 none;

          ::placeholder {
            color: #b9715c;
          }
        `
      : css`
          :focus {
            ${(props) =>
    props.value !== ''
      ? css`
                    border-color: #d3dee6;
                    box-shadow: 0 0 0 1px #d3dee6;
                  `
      : css`
                    box-shadow: 0 0 0 1px #e9eff4;
                  `}
          }
        `}
`

export default ({
  innerRef = null,
  label,
  id,
  disabled = false,
  error = false,
  onChangeHandler,
  ...inputProps
}) => (
  <InputWrapper>
    {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
    <StyledInput
      ref={innerRef}
      id={id}
      disabled={disabled}
      error={error}
      onChange={onChangeHandler}
      {...inputProps}
    />
  </InputWrapper>
)
