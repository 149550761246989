import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import styled from 'styled-components'

import Select from 'react-select'
import FormGroup from '../../components/UI/FormGroup'

import { setBookingTime } from '../../ducks/booking'

class AvailableTimeSelect extends React.Component {
  render() {
    const { booking, t } = this.props
    const { availability, bookingDate, bookingTime } = booking
    const { entities, loading, error } = availability

    const selectedDateAfternoon = moment(bookingDate, 'YYYY-MM-DDTHH:mm:ss')
      .hour(12)
      .minute(0)
      .second(0)
      .millisecond(0)
    const selectedDateEvening = moment(bookingDate, 'YYYY-MM-DDTHH:mm:ss')
      .hour(18)
      .minute(0)
      .second(0)
      .millisecond(0)

    const categories = [
      {
        label: 'Morning',
        options: []
      },
      {
        label: 'Afternoon',
        options: []
      },
      {
        label: 'Evening',
        options: []
      }
    ]

    let selectedValue = null
    let defaultValue = t('booking:chooseTime')

    entities.forEach((date) => {
      const momentDate = moment(date)
      const transformedTime = {
        value: date,
        label: momentDate.format('LT')
      }

      if (
        bookingTime !== null &&
        !selectedValue &&
        momentDate.isSame(moment(bookingTime))
      ) {
        selectedValue = transformedTime
      }

      if (momentDate.isBefore(selectedDateAfternoon)) {
        categories[0].options.push(transformedTime)
      } else if (
        momentDate.isSameOrAfter(selectedDateAfternoon) &&
        momentDate.isBefore(selectedDateEvening)
      ) {
        categories[1].options.push(transformedTime)
      } else if (momentDate.isSameOrAfter(selectedDateEvening)) {
        categories[2].options.push(transformedTime)
      }
    })

    if (!selectedValue && bookingTime) {
      defaultValue = moment(bookingTime).format('LT')
    }

    return (
      <FormGroup label={t('booking:time')}>
        <Select
          styles={{
            control: (prodided) => ({
              ...prodided,
              borderColor: '#e9eff4'
            })
          }}
          isLoading={loading}
          options={categories}
          onChange={this.onSelectValueChanged}
          value={selectedValue}
          defaultValue={defaultValue}
          placeholder={defaultValue}
          noOptionsMessage={() => t('booking:noTimeAvail')}
          loadingMessage={() => t('booking:loading')}
        />
      </FormGroup>
    )
  }

  onSelectValueChanged = ({ value }) => {
    this.props.setBookingTime(value)
  }
}

export default compose(
  connect(
    ({ booking }) => ({ booking }),
    { setBookingTime }
  ),
  withTranslation()
)(AvailableTimeSelect)
