import { apiError } from './index'

export default (res) => {
  const { data } = res
  if (data.success) {
    return data.payload
  }

  throw new apiError(data.error)
}
