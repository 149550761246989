import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import moment from 'moment'

import CalendarViewSwitcher from '../../components/CalendarViewSwitcher'
import DayList from './DayList'
import Drawer from './Drawer'

import arrowRightSvg from '../../images/icons/arrow-right.svg'
import hamburgerSvg from '../../images/icons/hamburger.svg'
import Button from '../../components/UI/Button'

import { changeDate, setView } from '../../ducks/calendar'
import { toggleDrawer } from '../../ducks/ui'

const StyledToolbarWrapper = styled.div`
  min-height: 89px;
  background-color: #f4f8f9;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledDateBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  /* @media (max-width: 930px) {
  } */
`

const StyledDateControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 1;
  margin-left: 26px;
  margin-right: 26px;

  @media (max-width: 930px) {
    margin-left: 0;
    margin-right: 0;
    flex: none;
  }
`

const StyledDate = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 600;

  color: #233539;
  margin: 0 12px;

  @media (max-width: 930px) {
    font-size: 16px;
  }

  @media (min-width: 930px) {
    flex: 0 1 215px;
    text-align: center;
  }
`

const StyledArrow = styled.div`
  background: url(${arrowRightSvg}) center center no-repeat;
  background-size: contain;

  ${(props) =>
    props.invert &&
    css`
      transform: rotate(180deg);
    `}
  min-width: 6px;
  min-height: 14px;

  padding: 4px;

  box-sizing: content-box !important;

  :hover {
    cursor: pointer;
  }
`

const StyledHamgurgerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledHamgurger = styled.div`
  width: 17px;
  min-height: 11px;
  padding: 5px 0 5px 5px;
  cursor: pointer;

  background: url(${hamburgerSvg}) center center no-repeat;
  background-size: contain;

  box-sizing: content-box !important;
`

class CustomToolbar extends Component {
  render() {
    const { calendar, ui, t } = this.props
    const { currentDate, selectedView } = calendar
    const { isMobile } = ui

    return (
      <React.Fragment>
        <StyledToolbarWrapper>
          <StyledDateBlock>
            <Button
              onClick={() => this.navigate('TODAY')}
              minWidth={false}
              outline
            >
              {t('calendar:today')}
            </Button>
            {!isMobile && this.renderDateControl(currentDate)}
          </StyledDateBlock>
          {!isMobile && (
            <CalendarViewSwitcher
              activeView={selectedView}
              setActiveView={this.changeView}
            />
          )}
          {isMobile && this.renderDateControl(currentDate)}
          {isMobile && (
            <StyledHamgurgerContainer>
              <StyledHamgurger onClick={this.props.toggleDrawer} />
            </StyledHamgurgerContainer>
          )}
          {isMobile && (
            <Drawer activeView={selectedView} setActiveView={this.changeView} />
          )}
        </StyledToolbarWrapper>

        {selectedView === 'day' && <DayList />}
      </React.Fragment>
    )
  }

  renderDateControl = (currentDate) => {
    console.log('Rerendering')
    const { t } = this.props
    const month = moment(currentDate.toDate()).format('MMMM')
    const year = moment(currentDate.toDate()).format('YYYY')
    console.log({ month, year })

    return (
      <StyledDateControl>
        <StyledArrow onClick={() => this.navigate('PREV')} invert={true} />
        <StyledDate>{t('calendar:controlData')(month, year)}</StyledDate>
        <StyledArrow onClick={() => this.navigate('NEXT')} />
      </StyledDateControl>
    )
  }

  navigate = (action) => {
    this.props.changeDate(action)
    // this.props.onNavigate(action)
  }

  changeView = (view) => {
    this.props.setView(view)
    this.props.onView(view)
  }
}

export default compose(
  withTranslation(),
  connect(
    ({ calendar, ui, localization }) => ({ calendar, ui, localization }),
    {
      changeDate,
      setView,
      toggleDrawer
    }
  )
)(CustomToolbar)
