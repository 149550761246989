class calendarRef {
  static ref = null
  static setCalendarRef(ref) {
    this.ref = ref

    console.log({ calendarRef: this.ref })
  }
}

export default calendarRef
