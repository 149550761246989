import { all, put, call, takeLatest, delay } from 'redux-saga/effects'
import moment from 'moment'

import { i18n } from '../helpers'

const initialState = {
  locale: 'en-GB'
}

export const moduleName = 'localization'

export const INIT_LOCALIZATION_REQUEST = `${moduleName}/INIT_LOCALIZATION_REQUEST`
export const INIT_LOCALIZATION_SUCCESS = `${moduleName}/INIT_LOCALIZATION_SUCCESS`
export const SET_LOCALIZATION = `${moduleName}/SET_LOCALIZATION`

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_LOCALIZATION:
      return { ...state, locale: payload }

    case INIT_LOCALIZATION_SUCCESS:
      return { ...state, locale: payload }

    default:
      return state
  }
}

export function initLocale() {
  return {
    type: INIT_LOCALIZATION_REQUEST
  }
}

export function setLocale(payload) {
  return {
    type: SET_LOCALIZATION,
    payload
  }
}

export const setLocaleSaga = function*({ payload }) {
  try {
    console.log(`Set locale to ${payload}`)
    moment.locale(payload)
    yield delay(10)
    yield call([i18n, i18n.changeLanguage], payload)
  } catch (e) {}
}

export const initLocaleSaga = function*() {
  try {
    const langLocalStore = yield call(
      [localStorage, localStorage.getItem],
      'i18nextLng'
    )
    moment.locale(langLocalStore)

    yield put({
      type: INIT_LOCALIZATION_SUCCESS,
      payload: langLocalStore
    })
  } catch (e) {}
}

export const saga = function*() {
  yield all([
    takeLatest(SET_LOCALIZATION, setLocaleSaga),
    takeLatest(INIT_LOCALIZATION_REQUEST, initLocaleSaga)
  ])
}
