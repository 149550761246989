import React, { Component } from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import styled from 'styled-components'

import FormGroup from '../../components/UI/FormGroup'

import { setBookingDate, changeCalendarMonth } from '../../ducks/booking'

const StyledCalendarWrapper = styled.div`
  .react-date-picker {
    display: block;
  }

  .react-date-picker__wrapper {
    display: none;
  }
`

const StyledFakeDatePicker = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  min-height: 38px;
  box-sizing: border-box;

  background-color: #fff;
  border: 1px solid #e9eff4;
  border-radius: 4px;

  padding: 2px 8px;

  cursor: default;
  outline: 0 !important;
  transition: all 100ms;
`

class BookingCalendar extends Component {
  handleMonthChange = ({ activeStartDate, view }) => {
    const { booking, changeCalendarMonth } = this.props
    const { loading } = booking

    if (view === 'month') {
      if (!loading) {
        changeCalendarMonth(activeStartDate)
      } else {
        console.log('Already loading')
      }
    }
  }

  state = {
    isOpen: false
  }

  onChange = (date) => {
    this.props.setBookingDate(date)
  }

  render() {
    const { booking, bookingSchedules, localization } = this.props

    return (
      <FormGroup label={'Date'}>
        <StyledCalendarWrapper>
          <StyledFakeDatePicker onClick={() => this.setState({ isOpen: true })}>
            {moment(booking.bookingDate).format('MMM D, YYYY')}
          </StyledFakeDatePicker>
          <DatePicker
            locale={localization.locale}
            isOpen={this.state.isOpen}
            onChange={this.onChange}
            value={booking.bookingDate}
            onCalendarClose={() => this.setState({ isOpen: false })}
            minDate={new Date()}
            prev2Label={null}
            next2Label={null}
            minDetail={'month'}
            onActiveDateChange={this.handleMonthChange}
            tileDisabled={({ date, view }) => {
              if (view === 'month') {
                const daysSchedule = bookingSchedules.days.find((e) =>
                  moment(e.start).isSame(moment(date), 'day')
                )

                return daysSchedule ? false : true
              }
            }}
          />
        </StyledCalendarWrapper>
      </FormGroup>
    )
  }
}

export default connect(
  ({ booking, bookingSchedules, localization }) => ({
    booking,
    bookingSchedules,
    localization
  }),
  { setBookingDate, changeCalendarMonth }
)(BookingCalendar)
