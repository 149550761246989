import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { AuthPage } from '../containers/Pages/Main'

const Routes = () => {
  return (
    <Switch>
      <Route path="/" component={AuthPage} />
    </Switch>
  )
}

export default Routes
