function getBrightness(r, g, b) {
  return (r * 299 + g * 587 + b * 114) / 1000
}

export function convertHex(hex) {
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return [r, g, b]
}

const arbitraryBrightness = 123

export default (color) => {
  const RGBcolors = convertHex(color)
  const brightness = getBrightness(...RGBcolors)

  return brightness > arbitraryBrightness
}
