import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en_gb from './langs/en_gb'
import ru_ru from './langs/ru_ru'
import id from './langs/id'
import ko from './langs/ko'
import th from './langs/th'
import vi from './langs/vi'
import my from './langs/my'
import zh_cn from './langs/zh_cn'
import zh_tw from './langs/zh_tw'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'EN-GB',
    debug: true,
    react: {
      wait: true,
      bindI18n: 'languageChanged'
    },
    resources: {
      'en-US': en_gb,
      'en-GB': en_gb,
      RU: ru_ru,
      ID: id,
      KO: ko,
      TH: th,
      VI: vi,
      MY: my,
      'zh-CN': zh_cn,
      'zh-TW': zh_tw
    }
  })

export default i18n
