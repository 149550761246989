import {
  all,
  put,
  call,
  takeEvery,
  takeLatest,
  select
} from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { getProfile } from '../api'
import { LOGOUT } from './auth'
import { apiError, showPopup } from '../helpers'
import { captureException } from '../helpers/sentry'

export const initialState = {
  first_name: '',
  last_name: '',
  id: null,
  outlet: {
    id: null,
    name: '',
    time_close: null,
    time_open: null
  },
  loading: false,
  error: null,
  picture: null,
  picture_thumb: null
}

export const moduleName = 'profile'

export const FETCH_PROFILE_REQUEST = `${moduleName}/FETCH_PROFILE_REQUEST`
export const FETCH_PROFILE_SUCCESS = `${moduleName}/FETCH_PROFILE_SUCCESS`
export const FETCH_PROFILE_FAIL = `${moduleName}/FETCH_PROFILE_FAIL`

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_PROFILE_REQUEST:
      return { ...state, loading: true, error: null }
    case FETCH_PROFILE_SUCCESS:
      return { ...state, loading: false, error: null, ...payload }
    case FETCH_PROFILE_FAIL:
      return { ...state, loading: false, error: payload }

    case LOGOUT:
      return {
        ...initialState
      }

    default: {
      return state
    }
  }
}

export const fetchProfile = () => ({
  type: FETCH_PROFILE_REQUEST
})

export const fetchProfileSaga = function*() {
  try {
    const response = yield call(getProfile)

    yield put({
      type: FETCH_PROFILE_SUCCESS,
      payload: response
    })
  } catch (e) {
    if (e instanceof apiError) {
      const { code, message } = e
      yield put({
        type: FETCH_PROFILE_FAIL,
        payload: {
          code,
          message
        }
      })
    } else {
      yield put({
        type: FETCH_PROFILE_FAIL,
        payload: {
          code: 0,
          message: 'unknown error'
        }
      })

      captureException(e)
    }
    yield put(showPopup(e))
    throw e
  }
}

export const saga = function*() {
  yield all([takeEvery(FETCH_PROFILE_REQUEST, fetchProfileSaga)])
}
