import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { initializeCalendar } from '../../../ducks/calendar'
import { setCheckingInput } from '../../../ducks/checkin'

import withDetectComponentSize from '../../HOC/withDetectComponentSize'

import Calendar from '../../DashboardPage/Calendar'
import Sidebar from '../../DashboardPage/Sidebar'
import TicketDetailsModal from '../../DashboardPage/TicketDetailsModal'

const StyledErrorOverlay = styled.div`
  z-index: 1000;
  background-wrappercolor: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const StyledPageWrapper = styled.div`
  position: relative;
  margin-left: 29px;
  display: flex;
  flex: 1;

  @media (max-width: 930px) {
    margin: 0 12px;
  }
`

class DashboardPage extends PureComponent {
  componentDidMount() {
    const { initializeCalendar, setCheckingInput } = this.props
    initializeCalendar()

    document.addEventListener('keyup', setCheckingInput)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.props.setCheckingInput)
  }

  render() {
    return <StyledPageWrapper>{this.renderMainSection()}</StyledPageWrapper>
  }

  renderMainSection = () => {
    const { isMobile } = this.props.ui

    // !TODO Maybe reselect?
    const renderFunction = isMobile
      ? this.renderMobileMainSection
      : this.renderDesktopMainSection

    return renderFunction()
  }

  renderMobileMainSection = () => {
    const { error: schedulesError } = this.props.schedules
    const { error: bookingsError } = this.props.bookings

    return (
      <React.Fragment>
        {(bookingsError !== null || schedulesError !== null) && (
          <StyledErrorOverlay>Error!</StyledErrorOverlay>
        )}
        <Calendar />
        <TicketDetailsModal />
      </React.Fragment>
    )
  }

  renderDesktopMainSection = () => {
    const { loading } = this.props.calendar
    const { error: schedulesError } = this.props.schedules
    const { error: bookingsError } = this.props.bookings

    return (
      <React.Fragment>
        {(bookingsError !== null || schedulesError !== null) && (
          <StyledErrorOverlay>Error!</StyledErrorOverlay>
        )}
        <Calendar />
        <Sidebar />
      </React.Fragment>
    )
  }
}

export default compose(
  connect(
    ({ bookings, calendar, schedules, ui }) => ({
      bookings,
      calendar,
      schedules,
      ui
    }),
    { initializeCalendar, setCheckingInput }
  ),
  withDetectComponentSize('calendar')
)(DashboardPage)
