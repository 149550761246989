import {
  all,
  put,
  call,
  takeEvery,
  takeLatest,
  select,
  delay
} from 'redux-saga/effects'

import { showPopup } from '../helpers'

import { captureException } from '../helpers/sentry'
import { sendCheckin } from '../api'
import { fetchBookings } from './bookings'

export const initialState = {
  checkinInput: ''
}

export const moduleName = 'checkin'

export const HANDLE_CHECKIN_INPUT_REQUEST = `${moduleName}/HANDLE_CHECKIN_INPUT_REQUEST`
export const HANDLE_CHECKIN_INPUT_SUCCESS = `${moduleName}/HANDLE_CHECKIN_INPUT_SUCCESS`
export const SET_CHECKIN_VALUE = `${moduleName}/SET_CHECKIN_VALUE`

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_CHECKIN_VALUE:
      return { ...state, checkinInput: payload }

    default: {
      return state
    }
  }
}

export const setCheckingInput = (event) => ({
  type: HANDLE_CHECKIN_INPUT_REQUEST,
  payload: event
})

export const setCheckingInputSaga = function*({ payload }) {
  try {
    const { checkinInput } = yield select(({ checkin }) => checkin)
    const { isOpen } = yield select(({ booking }) => booking.modal)
    if (isOpen) {
      return null
    }
    const { key } = payload

    if (key === 'Enter') {
      yield call(checkinSaga, checkinInput)
    } else if (key.length === 1) {
      yield put({
        type: SET_CHECKIN_VALUE,
        payload: checkinInput + key
      })
    }

    yield delay(2000)

    return yield put({
      type: SET_CHECKIN_VALUE,
      payload: ''
    })
  } catch (e) {
    console.error()
    yield put(showPopup(e))
  }
}

export const checkinSaga = function*(checkinInput) {
  try {
    const response = yield call(sendCheckin, checkinInput)

    if (response.length > 0) {
      yield put(fetchBookings())

      yield put(showPopup({ message: 'Checkin success' }, 'checkin'))
    }

    yield put({
      type: SET_CHECKIN_VALUE,
      payload: ''
    })
  } catch (e) {
    console.error(e)
    yield put(showPopup(e))
    captureException(e)
  }
}

export const saga = function*() {
  yield all([takeLatest(HANDLE_CHECKIN_INPUT_REQUEST, setCheckingInputSaga)])
}
