import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import moment from 'moment'

import { setActiveDate } from '../../ducks/calendar'

const Wrapper = styled.div`
  height: 70px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  background-color: #fff;

  border-bottom: 1px solid #e6e8f1;
`

const StyledDay = styled.div`
  flex: 1 0 0;
  margin: 14px 0;

  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #bdbdbd;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-transform: uppercase;

  cursor: pointer;
  transition: all 0.2s;

  .date {
    font-size: 20px;

    width: 31px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: #fff;

    transition: color 0.2s, background-color 0.2s;
  }

  ${(props) =>
    props.isToday &&
    css`
      color: #4192ec;

      .date {
        color: #bdbdbd;
      }
    `}

  ${(props) =>
    props.active &&
    css`
      color: #bdbdbd;

      .date {
        color: #fff;
        background-color: #4192ec;
      }
    `}
`

class DayList extends Component {
  render() {
    const { currentDate } = this.props.calendar
    const firstWeekDay = moment(currentDate.toDate()).startOf('week')

    return <Wrapper>{this.renderDays(firstWeekDay).map((x) => x)}</Wrapper>
  }

  renderDays = (firstDay) => {
    const { isMobile } = this.props.ui
    const { calendar, setActiveDate } = this.props
    const { currentDate } = calendar

    const _currentDate = moment(currentDate.toDate())

    const days = []
    for (let i = 0; i < 7; i++) {
      const currentDay = moment(firstDay).add(i, 'day')

      const dayName = isMobile
        ? currentDay.format('dddd')[0]
        : currentDay.format('dddd')
      const date = currentDay.format('DD')

      const isToday = currentDay.isSame(moment(), 'day')
      const isActive = currentDay.isSame(_currentDate, 'day')

      days.push(
        <StyledDay
          onClick={() => setActiveDate(currentDay)}
          key={+currentDay}
          active={isActive}
          isToday={isToday}
        >
          <span>{dayName}</span>
          <span className="date">{date}</span>
        </StyledDay>
      )
    }

    return days
  }
}

export default compose(
  connect(
    ({ calendar, ui }) => ({ calendar, ui }),
    { setActiveDate }
  ),
  withTranslation()
)(DayList)
