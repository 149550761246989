import { success, error } from 'react-notification-system-redux'

const notificationOpts = ({ title, message }) => ({
  title,
  message,
  position: 'tr',
  autoDismiss: 5
})

export default (data, type = 'error') => {
  if (type === 'error') {
    const { code, message } = data

    const notificationObject = {}
    if (data.hasOwnProperty('code')) {
      notificationObject.title = `code: ${code}`
      if (code === 0) {
        notificationObject.message = 'Unknown error'
      } else {
        notificationObject.message = `message: ${message}`
      }
    } else {
      notificationObject.message = `message: ${message}`
    }

    return error(notificationOpts(notificationObject))
  } else {
    const { message } = data

    const notificationObject = {
      message
    }

    return success(notificationOpts(notificationObject))
  }
}
