import { axios, handleApiErrors, getToken } from '../helpers'

export default (bookingId) => {
  const token = getToken()
  const app_id = window.appConfig.app_id

  const params = { session_id: token, app_id }

  return axios({
    url: `bookings/${bookingId}/complete`,
    method: 'POST',
    params
  }).then(handleApiErrors)
}
