import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'

import FormGroup from '../../components/UI/FormGroup'

import { fetchClients, selectClient } from '../../ducks/booking'

const GuestSelect = ({ fetchClients, booking, selectClient, t }) => {
  const {
    entities,
    loading,
    error,
    selectedClient,
    clientSearchName
  } = booking.clients

  const onSelectValueChanged = ({ value }) => {
    if (value) {
      const { membership, ...client } = entities.find((e) => e.id === value)
      selectClient(client)
    } else {
      selectClient({
        first_name: '',
        last_name: '',
        second_name: '',
        id: null
      })
    }
  }

  let options = []

  if (!selectedClient.id) {
    options.push({
      label: t('booking:noClientSelected'),
      value: null
    })
  }

  options = [
    ...options,
    ...entities.map(({ id, first_name, last_name, second_name }) => ({
      label: [last_name, first_name, second_name].join(' '),
      value: id
    }))
  ]

  const selectedValue = options.find((o) => o.value === selectedClient.id)

  return (
    <FormGroup label={t('booking:client')}>
      <Select
        styles={{
          control: (prodided) => ({
            ...prodided,
            borderColor: '#e9eff4'
          })
        }}
        className="custom_select_style"
        isLoading={loading}
        value={selectedValue}
        options={options}
        onInputChange={(e, { action }) => {
          if (e !== clientSearchName && action === 'input-change') {
            fetchClients(e)
          }
        }}
        placeholder={t('booking:noClientSelected')}
        onChange={onSelectValueChanged}
        noOptionsMessage={() => t('booking:noClientFound')}
        loadingMessage={() => t('booking:loading')}
      />
    </FormGroup>
  )
}

export default compose(
  connect(
    ({ booking }) => ({ booking }),
    {
      fetchClients,
      selectClient
    }
  ),
  withTranslation()
)(GuestSelect)
