import { axios, handleApiErrors, getToken } from '../helpers'

export default ({ card_id, offer_id, outlet_id, from }) => {
  const token = getToken()
  const app_id = window.appConfig.app_id

  const params = {
    session_id: token,
    card_id,
    offer_id,
    outlet_id,
    from,
    app_id
  }

  return axios({
    url: 'bookings',
    method: 'POST',
    params
  }).then(handleApiErrors)
}
