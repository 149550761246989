import {
  all,
  put,
  call,
  take,
  takeEvery,
  takeLatest,
  select,
  fork,
  cancel,
  cancelled,
  delay
} from 'redux-saga/effects'
import moment from 'moment'

import { getBookings, markCompleted as markCompletedRequest } from '../api'
import { apiError, showPopup } from '../helpers'
import { captureException } from '../helpers/sentry'

import { setSelectedEvent } from './calendar'
import { logout, LOGOUT } from './auth'

export const initialState = {
  entities: [],
  todaysBooking: [],
  loading: false,
  error: null,
  backgroundSync: false
}

const syncTimeout = parseInt(window.appConfig.backgroundSyncTimeout)

export const moduleName = 'bookings'

export const FETCH_BOOKINGS_REQUEST = `${moduleName}/FETCH_BOOKINGS_REQUEST`
export const FETCH_BOOKINGS_SUCCESS = `${moduleName}/FETCH_BOOKINGS_SUCCESS`
export const FETCH_BOOKINGS_FAIL = `${moduleName}/FETCH_BOOKINGS_FAIL`

export const MARK_COMPLETED_REQUEST = `${moduleName}/MARK_COMPLETED_REQUEST`
export const MARK_COMPLETED_SUCCESS = `${moduleName}/MARK_COMPLETED_SUCCESS`
export const MARK_COMPLETED_FAIL = `${moduleName}/MARK_COMPLETED_FAIL`

export const START_BACKGROUND_SYNC = `${moduleName}/START_BACKGROUND_SYNC`
export const STOP_BACKGROUND_SYNC = `${moduleName}/STOP_BACKGROUND_SYNC`

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_BOOKINGS_REQUEST:
    case MARK_COMPLETED_REQUEST:
      return { ...state, loading: true, error: null }

    case FETCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ...payload
      }

    case FETCH_BOOKINGS_FAIL:
    case MARK_COMPLETED_FAIL:
      return { ...state, loading: false, error: payload }

    case LOGOUT:
      return { ...initialState }

    default: {
      return state
    }
  }
}

export const fetchBookings = () => ({
  type: FETCH_BOOKINGS_REQUEST
})

export const markCompleted = (id) => ({
  type: MARK_COMPLETED_REQUEST,
  payload: id
})

export const fetchBookingsSaga = function*({ to, from }) {
  try {
    const { selectedEvent } = yield select(({ calendar }) => calendar)
    let { todaysBooking } = yield select(({ bookings }) => bookings)

    const formattedFrom = moment(from)
      .locale('en')
      .format('DDMMYYYY')
    const formattedTo = moment(to)
      .locale('en')
      .format('DDMMYYYY')

    const response = yield call(getBookings, {
      from: formattedFrom,
      to: formattedTo
    })

    const currentDateMoment = moment()

    if (
      currentDateMoment.isSameOrAfter(from, 'day') &&
      currentDateMoment.isSameOrBefore(to, 'day')
    ) {
      todaysBooking = response.filter((event) => {
        const resultDate = moment(event.treatmentStart, 'YYYY-MM-DDTHH:mm:ss')

        if (currentDateMoment.isSame(resultDate, 'day')) {
          return true
        } else {
          return false
        }
      })
    }

    const containsSelectedEvent = response.find((e) => e.id === selectedEvent)

    if (!containsSelectedEvent) {
      yield put(setSelectedEvent(null))
    }

    yield put({
      type: FETCH_BOOKINGS_SUCCESS,
      payload: { entities: response, todaysBooking }
    })
  } catch (e) {
    if (e instanceof apiError) {
      const { code, message } = e
      yield put({
        type: FETCH_BOOKINGS_FAIL,
        payload: {
          code,
          message
        }
      })
      if (code === 4) {
        yield put(logout())
      }
    } else {
      yield put({
        type: FETCH_BOOKINGS_FAIL,
        payload: {
          code: 0,
          message: 'unknown error'
        }
      })

      captureException(e)
    }
    yield put(showPopup(e))
  }
}

export const markCompletedSaga = function*({ payload }) {
  try {
    yield call(markCompletedRequest, payload)

    const { from, to } = yield select(({ calendar }) => calendar)

    yield call(fetchBookingsSaga, { from, to })
  } catch (e) {
    if (e instanceof apiError) {
      const { code } = e
      if (code === 4) {
        yield put(logout())
      }
    } else {
      captureException(e)
    }
    yield put(showPopup(e))
  }
}

export const backgroundSyncSaga = function*() {
  if (!syncTimeout) {
    return null
  }

  while (yield take(START_BACKGROUND_SYNC)) {
    const bgSyncTask = yield fork(fetchBackground)
    yield take(STOP_BACKGROUND_SYNC)
    yield cancel(bgSyncTask)
  }
}

export const fetchBackground = function*() {
  try {
    while (true) {
      yield delay(syncTimeout * 1000)
      const { to, from } = yield select(({ calendar }) => calendar)
      yield call(fetchBookingsSaga, { to, from })
    }
  } finally {
    if (yield cancelled()) {
      // cancelled
    }
  }
}

export const saga = function*() {
  yield all([
    takeEvery(FETCH_BOOKINGS_REQUEST, fetchBookingsSaga),
    takeLatest(MARK_COMPLETED_REQUEST, markCompletedSaga),
    call(backgroundSyncSaga)
  ])
}
