import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'

import { updateElementSize } from '../../ducks/ui'

export default (name) => (BaseComponent) => {
  class ReactHOC extends Component {
    componentDidMount() {
      this.props.updateElementSize(name, {
        width: window.innerWidth
      })
    }

    onResize = (containerWidth) => {
      this.props.updateElementSize(name, {
        width: containerWidth
      })
    }

    render() {
      return (
        <React.Fragment>
          <ReactResizeDetector handleWidth onResize={this.onResize} />

          <BaseComponent {...this.props} />
        </React.Fragment>
      )
    }
  }

  ReactHOC.displayName = 'WithDetectSize'

  return connect(
    null,
    { updateElementSize }
  )(ReactHOC)
}
