export default {
  auth: {
    byEmail: 'Đăng nhập bằng email và mật khẩu',
    byCard: 'Đăng nhập bằng mã số',
    login: 'Đăng nhập',
    cardNumber: 'Mã số',
    email: 'Email',
    password: 'Mật khẩu',
    logout: 'Thoát'
  },
  calendar: {
    today: 'Hôm nay',
    day: 'Ngày',
    week: 'Tuần',
    month: 'Tháng',
    controlData: (month, year) => `${month} Năm ${year}`,
    showMore: (count) => `${count}Thêm`
  },
  booking: {
    loading: 'Đang tải',
    bookNow: 'Đặt ngay',
    details: 'Chi tiết Booking',
    noEvent: 'Chưa có mục được chọn',
    minutesShort: 'Phút',
    status: 'Tình trạng',
    markCompleted: 'Đã chọn xong',
    rebook: 'Đặt lại',
    time: 'Thời gian',
    chooseTime: 'Chọn thời gian',
    noTimeAvail: 'Không còn thời gian trống',
    client: 'Khách hàng',
    noClientSelected: 'Không có khách hàng được chọn',
    noClientFound: 'Không tìm thấy thông tin khách hàng',
    offers: 'Dịch vụ',
    noOffersSelected: 'Không có dịch vụ nào được chọn',
    noOffersFound: 'Không tìm thấy dịch vụ'
  },
  schedule: {
    upcomingToday: 'Lịch hẹn trong ngày',
    noUpcoming: 'Không có lịch hẹn trong ngày'
  },
  statuses: {
    COMPLETE: 'Hoàn tất',
    INCOMPLETE: 'Chưa hoàn tất',
    IN_PROCESS: 'Đang hoàn tất',
    CONFIRMED: 'Xác nhận',
    NO_SHOW: 'Khách không đến',
    CANCEL: 'Hủy hẹn',
    DONE: 'Đã hoàn thành',
    UNDEFINED: 'Không xác nhận',
    TENTATIVE: 'Chưa chắc chắn'
  }
}
