import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import Notifications from '../containers/Notifications'
import AppWrapper from '../components/UI/AppWrapper'

import history from '../history'

import Main from './Main'
import Dashboard from './Dashboard'

const Routes = () => {
  return (
    <AppWrapper>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/" component={Main} />
        </Switch>
      </ConnectedRouter>
      <Notifications />
    </AppWrapper>
  )
}

export default Routes
