import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import userImage from '../../images/user.png'
import logoutIcon from '../../images/icons/logout_icon.svg'

import { logout } from '../../ducks/auth'
import { setLocale } from '../../ducks/localization'

const Wrapper = styled.div`
  margin: 28px 28px 36px 28px;
  display: flex;
`

const StyledUserImg = styled.div`
  width: 43px;
  height: 43px;
  margin-right: 10px;

  border-radius: 50%;
  background: url(${(props) => (props.imageUrl ? props.imageUrl : userImage)})
    center center no-repeat;
  background-size: contain;
`

const StyledInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledUserName = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #3e494d;
`

const StyledLanguageChangeWrapper = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  background-color: #fff;

  .item {
    cursor: pointer;
  }
`

const StyledLogoutButton = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  color: #4da1ff;

  .icon {
    width: 10px;
    height: 10px;

    background: url(${logoutIcon}) center center no-repeat;
    background-size: contain;

    margin-right: 5px;
  }

  :hover {
    opacity: 0.8;
  }
`

class User extends Component {
  state = {
    langOpen: false
  }

  render() {
    const { profile, t } = this.props
    const { first_name, last_name, picture } = profile

    return (
      <Wrapper>
        <Helmet>
          <title>
            {first_name} {last_name} - TNG Schedule
          </title>
        </Helmet>
        <StyledUserImg onClick={this.toggleLangList} imageUrl={picture} />
        <StyledInfoBlock>
          <StyledUserName>
            {first_name} {last_name}
          </StyledUserName>
          <StyledLogoutButton onClick={() => this.props.logout()}>
            <div className="icon" />
            {t('auth:logout')}
          </StyledLogoutButton>
        </StyledInfoBlock>
        {this.renderLocaleList()}
      </Wrapper>
    )
  }

  toggleLangList = () =>
    this.setState((state) => ({
      langOpen: !state.langOpen
    }))

  renderLocaleList = () => {
    const { setLocale } = this.props
    // const { langOpen }
    if (!this.state.langOpen) return null

    const localeList = {
      'en-US': 'English (US)',
      'en-GB': 'English (UK)',
      RU: 'Russian',
      ID: 'Indonesian',
      KO: 'Korean',
      TH: 'Thai',
      VI: 'Vietnamese',
      MY: 'Burmese',
      'zh-CN': 'Chinese (Simplified)',
      'zh-TW': 'Chinese (Traditional)'
    }

    return (
      <StyledLanguageChangeWrapper>
        {Object.keys(localeList).map((key) => {
          return (
            <div
              className="item"
              key={key}
              onClick={() => {
                this.toggleLangList()
                setLocale(key)
              }}
            >
              {localeList[key]}
            </div>
          )
        })}
      </StyledLanguageChangeWrapper>
    )
  }
}

export default compose(
  connect(
    ({ profile, localization }) => ({ profile, localization }),
    { logout, setLocale }
  ),
  withTranslation()
)(User)
