import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'

import FormGroup from '../../components/UI/FormGroup'

import { selectOffer, setOfferFilter } from '../../ducks/booking'

class OfferSelect extends React.PureComponent {
  render() {
    const { booking, t } = this.props
    const { entities, loading, offerId } = booking.offers

    const categories = []

    let selectedOffer = null

    entities.forEach((offer) => {
      let category = categories.find((c) => c.label === offer.folder)
      if (!category) {
        category = {
          label: offer.folder,
          options: []
        }
        categories.push(category)
      }

      const transformedOffer = {
        value: offer.id,
        label: offer.name
      }

      if (offer.id === offerId) {
        selectedOffer = transformedOffer
      }

      category.options.push(transformedOffer)
    })

    return (
      <FormGroup label={t('booking:offers')}>
        <Select
          styles={{
            control: (prodided) => ({
              ...prodided,
              borderColor: '#e9eff4'
            })
          }}
          value={selectedOffer}
          isLoading={loading}
          options={categories}
          onChange={this.onSelectValueChanged}
          placeholder={t('booking:noOffersSelected')}
          noOptionsMessage={() => t('booking:noOffersFound')}
          loadingMessage={() => t('booking:loading')}
        />
      </FormGroup>
    )
  }

  onSelectValueChanged = ({ value }) => {
    this.props.selectOffer(value)
  }
}

export default compose(
  connect(
    ({ booking }) => ({ booking }),
    { selectOffer, setOfferFilter }
  ),
  withTranslation()
)(OfferSelect)
