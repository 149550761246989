import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as notifications } from 'react-notification-system-redux'

// import { reducer as formReducer } from 'redux-form'

import authReducer, { moduleName as authModule } from '../ducks/auth'
import bookingsReducer, {
  moduleName as bookingsModule
} from '../ducks/bookings'
import bookingReducer, { moduleName as bookingModule } from '../ducks/booking'
import calendarReducer, {
  moduleName as calendarModule
} from '../ducks/calendar'
import profileReducer, { moduleName as profileModule } from '../ducks/profile'
import schedulesReducer, {
  moduleName as schedulesModule
} from '../ducks/schedules'
import bookingSchedulesReducer, {
  moduleName as bookingSchedulesModule
} from '../ducks/bookingSchedules'
import uiReducer, { moduleName as uiModule } from '../ducks/ui'
import localizationReducer, {
  moduleName as localizationModule
} from '../ducks/localization'
import checkinReducer, { moduleName as checkinModule } from '../ducks/checkin'
// import appReducer, { moduleName as appModule } from '../ducks/app'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    [authModule]: authReducer,
    [bookingModule]: bookingReducer,
    [bookingsModule]: bookingsReducer,
    [calendarModule]: calendarReducer,
    [profileModule]: profileReducer,
    [schedulesModule]: schedulesReducer,
    [bookingSchedulesModule]: bookingSchedulesReducer,
    [uiModule]: uiReducer,
    [localizationModule]: localizationReducer,
    [checkinModule]: checkinReducer,
    notifications
    // form: formReducer,
    // [appModule]: appReducer,
    // notifications
  })
