import React from 'react'
import styled from 'styled-components'

import ovalSvg from '../../images/icons/oval.svg'

const LoaderWrapper = styled.div`
  z-index: 5000;
  position: absolute;
  top: 0;
  left: -200px;
  width: calc(100% + 400px);
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledOval = styled.div`
  height: 30px;
  width: 30px;
  background: url(${ovalSvg}) center center no-repeat;
  background-size: contain;
`

export default () => (
  <LoaderWrapper>
    <StyledOval />
  </LoaderWrapper>
)
