import React, { Component } from 'react'
import { connect } from 'react-redux'

import { checkAuth } from '../../ducks/auth'

export default (BaseComponent) => {
  class ReactHOC extends Component {
    componentDidMount() {
      if (!this.props.auth.initialized) {
        this.props.checkAuth()
      }
    }

    render() {
      const { initialized } = this.props.auth

      if (!initialized) {
        return null
      }

      return <BaseComponent {...this.props} />
    }
  }

  ReactHOC.displayName = 'WithAuth'

  return connect(
    ({ auth }) => ({ auth }),
    { checkAuth }
  )(ReactHOC)
}
