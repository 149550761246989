export default {
  auth: {
    byEmail: '电邮及密码认证',
    byCard: '卡号认证',
    login: '登入',
    cardNumber: '卡号',
    email: '电邮',
    password: '密码',
    logout: '登出'
  },
  calendar: {
    today: '今天',
    day: '日',
    week: '周',
    month: '月',
    controlData: (month, year) => `${month} ${year}`,
    showMore: (count) => `加多${count}个`
  },
  booking: {
    loading: '载入中',
    bookNow: '立即预订',
    details: '预订详情',
    noEvent: '没有选择活动',
    minutesShort: '分钟',
    status: '状态',
    markCompleted: '已完成',
    rebook: '重新预订',
    time: '时间',
    chooseTime: '选择预订时间',
    noTimeAvail: '没有时间能够预订',
    client: '客人',
    noClientSelected: '没有选择客人',
    noClientFound: '找不到客人',
    offers: '优惠',
    noOffersSelected: '没有选择优惠',
    noOffersFound: '找不到优惠'
  },
  schedule: {
    upcomingToday: '今天的预订',
    noUpcoming: '今天没有预订'
  },
  statuses: {
    COMPLETE: '预订',
    INCOMPLETE: '未完成',
    IN_PROCESS: '进行中',
    CONFIRMED: '已确认',
    NO_SHOW: '缺席',
    CANCEL: '取消',
    DONE: '已完成',
    UNDEFINED: '未定义',
    TENTATIVE: '暂定'
  }
}
