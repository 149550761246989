import { axios, handleApiErrors } from '../helpers'

export default ({ email, password, card, authByCard }) => {
  const app_id = window.appConfig.app_id
  const params = {
    app_id
  }
  const data = authByCard
    ? `magstripe=${card}`
    : `username=${email}&password=${password}`

  return axios({
    url: 'signin',
    method: 'POST',
    data,
    params
  }).then(handleApiErrors)
}
