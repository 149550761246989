import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import styled, { css } from 'styled-components'

import { rebookEvent } from '../../ducks/booking'
import { markCompleted } from '../../ducks/bookings'

import Button from '../../components/UI/Button'

const StyledWrapper = styled.div`
  margin: 0 28px 33px 28px;

  @media (max-width: 930px) {
    margin: 0 20px 21px 20px;
  }
`

const StyledTitle = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #bdbdbd;
  text-transform: uppercase;

  padding-bottom: 5px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 22px;
`

const StyledTicketData = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: #323c47;

  margin-bottom: 7px;

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `}
`

const StyledTicketStatus = styled.span`
  display: inline-block;
  text-transform: capitalize;

  background-color: rgba(255, 109, 74, 0.1);
  color: #ff6d4a;
  padding: 2px 13px;
  border-radius: 4px;
`

const StyledButtonsBar = styled.div`
  margin-top: 10px;
  display: flex;

  button {
    margin-right: 5px;
  }

  button:last-child {
    margin-right: 0;
  }
`

const renderTreatmentName = ({ treatmentName }, key) => {
  if (!treatmentName) return null

  return <StyledTicketData key={key}>{treatmentName}</StyledTicketData>
}

const renderClientName = ({ client }, key) => {
  if (!client || !client.id) return null

  const clientName = [
    client.lastName,
    client.firstName,
    client.secondName
  ].join(' ')

  return <StyledTicketData key={key}>{clientName}</StyledTicketData>
}

const renderRoom = ({ facility }, key) => {
  if (!facility) return null

  return <StyledTicketData key={key}>{facility}</StyledTicketData>
}

const renderComment = ({ comment }, key) => {
  if (!comment) return null

  return <StyledTicketData key={key}>{comment}</StyledTicketData>
}

const renderTicketData = (event) => {
  const renderFunctions = {
    treatmentName: renderTreatmentName,
    clientName: renderClientName,
    room: renderRoom,
    comment: renderComment
  }

  const order = window.appConfig.details

  return order.map((item) => {
    if (renderFunctions.hasOwnProperty(item)) {
      return renderFunctions[item](event, item)
    }
  })
}

const TicketDetail = ({
  calendar,
  bookings,
  rebookEvent,
  markCompleted,
  t
}) => {
  const { selectedEvent } = calendar
  const { entities, todaysBooking } = bookings

  if (!selectedEvent) {
    return (
      <StyledWrapper>
        <StyledTitle>{t('booking:details')}</StyledTitle>
        <StyledTicketData bold>{t('booking:noEvent')}</StyledTicketData>
      </StyledWrapper>
    )
  }

  const event =
    todaysBooking.find((e) => e.id === selectedEvent) ||
    entities.find((e) => e.id === selectedEvent)

  if (!event) {
    return <h1>error</h1>
  }

  return (
    <StyledWrapper>
      <StyledTitle>{t('booking:details')}</StyledTitle>
      <StyledTicketData bold>
        {moment(event.treatmentStart, 'YYYY-MM-DDTHH:mm:ss').format('LT')}
        {' - '}
        {moment(event.treatmentEnd, 'YYYY-MM-DDTHH:mm:ss').format('LT')} (
        {moment(event.treatmentEnd).diff(
          moment(event.treatmentStart),
          'minutes'
        )}{' '}
        {t('booking:minutesShort')})
      </StyledTicketData>

      {renderTicketData(event)}

      <StyledTicketData>
        {t('booking:status')}:{' '}
        <StyledTicketStatus>
          {t(`statuses:${event.status}`).toLowerCase()}
        </StyledTicketStatus>
      </StyledTicketData>

      <StyledButtonsBar>
        <Button
          outline={true}
          minWidth={false}
          disabled={event.status === 'DONE'}
          onClick={() => markCompleted(event.id)}
        >
          {t('booking:markCompleted')}
        </Button>

        {event.client && event.client.id && (
          <Button
            stretch
            minWidth={false}
            onClick={() =>
              rebookEvent(
                event.client.id,
                {
                  // TODO map camelCase to snake_case on server
                  id: event.client.id,
                  last_name: event.client.lastName,
                  first_name: event.client.firstName,
                  second_name: event.client.secondName
                },
                event.offerId,
                event.treatmentName,
                event.treatmentStart
              )
            }
          >
            {t('booking:rebook')}
          </Button>
        )}
      </StyledButtonsBar>
    </StyledWrapper>
  )
}

export default compose(
  connect(
    ({ calendar, bookings }) => ({
      calendar,
      bookings
    }),
    { rebookEvent, markCompleted }
  ),
  withTranslation()
)(TicketDetail)
