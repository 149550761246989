import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Calendar from 'react-big-calendar'
// import { Calendar, momentLocalizer } from 'react-big-calendar'
import styled, { css } from 'styled-components'
import moment from 'moment'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import CustomToolbar from './CalendarCustomToolbar'
import { EventComponent } from '../../components/Calendar'
import Loader from '../../components/UI/Loader'
import BookingModal from './BookingModal'
import WeekHeader from './WeekHeader'
import MonthHeader from './MonthHeader'
import MonthDateHeader from './MonthDateHeader'
import MonthEventComponent from './MonthEventComponent'

import { calendarRefStorage, getEventBackgroundBrightness } from '../../helpers'
import { setSelectedEvent, setActiveDate } from '../../ducks/calendar'
import {
  setBookingDate,
  toggleModal,
  setBookingTime
} from '../../ducks/booking'

const localizer = Calendar.momentLocalizer(moment)

const BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.02)'

const StyledCalendarWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: 931px) {
    max-width: calc(100% - 307px);
  }
`

class EmployeeCalendar extends PureComponent {
  constructor(props) {
    super(props)

    this._calendarRef = React.createRef()
  }

  componentDidMount() {
    calendarRefStorage.setCalendarRef(this._calendarRef)
  }

  componentWillUnmount() {
    calendarRefStorage.setCalendarRef(null)
  }

  render() {
    const { schedules, calendar, t } = this.props
    const { selectedView, currentDate, loading } = calendar

    const { min, max } = schedules

    return (
      <React.Fragment>
        <BookingModal {...this.state} />
        <StyledCalendarWrapper>
          {loading && <Loader />}
          <Calendar
            ref={this._calendarRef}
            culture={this.props.localization.locale}
            localizer={localizer}
            defaultDate={currentDate.toDate()}
            defaultView={selectedView}
            events={this.props.bookings.entities}
            popup
            onSelectEvent={this._onSelectEvent}
            eventPropGetter={this._eventPropGetter}
            timeslots={2}
            slotPropGetter={this._slotPropGetter}
            dayPropGetter={this.customDayPropGetter}
            onSelectSlot={this._onSelectSlot}
            selectable
            onDrillDown={this._onDrillDown}
            // onSelecting={() => {
            //   setTimeout(function() {
            //     debugger
            //   }, 1000)
            // }}
            selected={this._getSelectedEvent()}
            // longPressThreshold={10}
            min={min}
            max={max}
            messages={this._messages}
            components={this._components}
            titleAccessor={this._titleAccessor}
            startAccessor={this._startAccessor}
            endAccessor={this._endAccessor}
          />
        </StyledCalendarWrapper>
      </React.Fragment>
    )
  }

  _endAccessor = (event) =>
    moment(event.treatmentEnd, 'YYYY-MM-DDTHH:mm:ss').toDate()

  _startAccessor = (event) =>
    moment(event.treatmentStart, 'YYYY-MM-DDTHH:mm:ss').toDate()

  _titleAccessor = (event) => {
    return event.treatmentName
  }

  _components = {
    event: EventComponent,
    toolbar: CustomToolbar,
    week: {
      header: WeekHeader
    },
    month: {
      header: MonthHeader,
      event: MonthEventComponent,
      dateHeader: MonthDateHeader
    }
  }

  _messages = {
    showMore: (count) => this.props.t('calendar:showMore')(count)
  }

  _onDrillDown = (props) => {
    const momentDate = moment(props, 'YYYY-MM-DDTHH:mm:ss')
    const utcOffset = momentDate.utcOffset()
    momentDate.add(utcOffset, 'minute')
    this.props.setActiveDate(momentDate)
  }

  _getSelectedEvent = () => {
    const { selectedEvent } = this.props.calendar
    const { entities } = this.props.bookings

    if (selectedEvent === null) {
      return {}
    } else {
      const _event = entities.find((e) => e.id === selectedEvent)

      return _event || {}
    }
  }

  _onSelectSlot = ({ start, ...props }) => {
    if (this.props.calendar.selectedEvent) {
      return this.setSelectedEvent(null)
    }
    const { entities } = this.props.schedules

    const momentStart = moment(start)

    const currentDateString = momentStart.locale('en').format('DDMMYYYY')

    const _currentDaySchedule = entities[currentDateString]

    if (_currentDaySchedule) {
      let isInSchedule = false

      const checkIsInScdule = (interval) => {
        const intervalStart = moment(interval.start)
        const intervalEnd = moment(interval.end)

        if (
          moment().isSameOrBefore(momentStart) &&
          momentStart.isBetween(intervalStart, intervalEnd, 'minute', '[)')
        ) {
          isInSchedule = true
        }
      }

      if (_currentDaySchedule.length > 1) {
        _currentDaySchedule.forEach((interval) => checkIsInScdule(interval))
      } else {
        checkIsInScdule(_currentDaySchedule)
      }

      if (isInSchedule) {
        let x, y
        if (props.box) {
          x = props.box.x
          y = props.box.y
        } else if (props.bounds) {
          x = props.bounds.x
          y = props.bounds.y
        }

        requestAnimationFrame(() => {
          this.props.setBookingDate(start)
          this.props.setBookingTime(start)
          this.props.toggleModal({ isOpen: true, x, y })
        })
      }
    }
  }

  setSelectedEvent = (id) => {
    this.props.setSelectedEvent(id)
  }

  _onSelectEvent = (props) => {
    this.setSelectedEvent(props.id)
  }

  customDayPropGetter = (date) => {
    if (this.props.calendar.selectedView !== 'month') {
      return {}
    }

    const momentDate = moment(date)
    const momentDateString = momentDate.locale('en').format('DDMMYYYY')
    const utcOffset = momentDate.utcOffset()
    momentDate.add(utcOffset, 'minute')

    const isInSchedule = this.props.schedules.entities[momentDateString]

    if (!isInSchedule) {
      return {
        className: 'disabled_day'
      }
    } else {
      return {}
    }
  }

  _eventPropGetter = ({ id, status, color }) => {
    if (this.props.calendar.selectedView === 'month') {
      return {}
    }

    const eventBorderStyles = {
      COMPLETE: 'rgb(244, 250, 255)',
      IN_PROCESS: 'rgb(176, 209, 255)',
      DONE: 'rgb(51, 255, 0)',
      INCOMPLETE: 'rgb(253, 242, 226)',
      CONFIRMED: 'rgb(250, 183, 43)',
      CANCEL: 'rgb(255, 5, 0)',
      NO_SHOW: 'rgb(215, 215, 215)',
      MISSED: 'Tomato'
    }

    const commonStyles = {
      borderColor: 'transparent',
      borderRadius: '5px',
      borderLeftWidth: '5px',
      borderLeftColor: eventBorderStyles[status] || 'red'
    }

    let backgroundColor
    let zIndex

    const leftPaddedColor = `000000${color}`.slice(-6)

    if (id === this.props.calendar.selectedEvent) {
      backgroundColor = '#D4EFFF80'
      zIndex = 100
    } else {
      backgroundColor = `#${leftPaddedColor}80`
      zIndex = 'unset'
    }

    const fontColor = getEventBackgroundBrightness(backgroundColor)
      ? '#ffffff'
      : '#39424d'

    return {
      style: {
        ...commonStyles,
        backgroundColor,
        zIndex,
        color: fontColor
      }
    }
  }

  // Color slots for disabled time
  _slotPropGetter = (date) => {
    const { loading, entities } = this.props.schedules

    if (!loading) {
      const momentDate = moment(date)
      const momentDateString = momentDate.locale('en').format('DDMMYYYY')
      const _currentDaySchedule = entities[momentDateString]

      if (_currentDaySchedule) {
        let isInSchedule = false
        let currentSchedule = null

        const checkIsInScdule = (interval) => {
          const intervalStart = moment(interval.start)
          const intervalEnd = moment(interval.end)

          const leftEdge = moment(momentDate).subtract(30, 'minute')
          const rightEdge = moment(momentDate).add(30, 'minute')

          if (
            momentDate.isBetween(intervalStart, intervalEnd, 'minute', '[)') ||
            leftEdge.isBetween(intervalStart, intervalEnd, 'minute', '[)') ||
            rightEdge.isBetween(intervalStart, intervalEnd, 'minute', '[)')
          ) {
            isInSchedule = true
            currentSchedule = interval
          }
        }

        if (_currentDaySchedule.length > 1) {
          _currentDaySchedule.forEach((interval) => checkIsInScdule(interval))
        } else {
          checkIsInScdule(_currentDaySchedule)
        }

        if (isInSchedule) {
          const { start, end } = currentSchedule

          const startHours = moment(start).hour()
          const startMinute = moment(start).minute()
          const endHours = moment(end).hour()
          const endMinute = moment(end).minute()
          const hours = moment(date).hour()
          const minutes = moment(date).minute()

          const style = {
            borderTopColor: 'transparent'
          }

          const backgroundColor = BACKGROUND_COLOR
          let backgroundImage

          if (startHours === hours) {
            if (
              (minutes === 0 && startMinute > minutes) ||
              (minutes === 30 && startMinute > minutes)
            ) {
              const percent = (Math.abs(startMinute - minutes) / 30) * 100
              backgroundImage = `linear-gradient(to bottom, ${BACKGROUND_COLOR}, ${BACKGROUND_COLOR} ${percent}%, transparent ${percent}%)`
              style.background = backgroundImage

              if (hours <= startHours || hours >= endHours) {
                return {
                  style
                }
              }
            }
          } else if (endHours === hours) {
            if (
              (minutes === 0 && endMinute > minutes) ||
              (minutes === 30 && endMinute > minutes)
            ) {
              const percent = (Math.abs(endMinute - minutes) / 30) * 100
              backgroundImage = `linear-gradient(to bottom, transparent, transparent ${percent}%, ${BACKGROUND_COLOR} ${percent}%)`
              style.background = backgroundImage

              if (hours <= startHours || hours >= endHours) {
                return {
                  style
                }
              }
            }
          }

          style.backgroundColor = backgroundColor

          if (hours < startHours || hours >= endHours) {
            return {
              style
            }
          }
        } else {
          return {
            style: {
              backgroundColor: BACKGROUND_COLOR,
              border: 'transparent'
            }
          }
        }
      } else {
        return {
          style: {
            backgroundColor: BACKGROUND_COLOR,
            border: 'transparent'
          }
        }
      }
    }
  }
}

export default compose(
  connect(
    ({ bookings, calendar, schedules, localization }) => ({
      bookings,
      calendar,
      schedules,
      localization
    }),
    {
      setSelectedEvent,
      setBookingDate,
      setBookingTime,
      setActiveDate,
      toggleModal
    }
  ),
  withRouter,
  withTranslation()
)(EmployeeCalendar)
