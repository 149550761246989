import React from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import AuthForm from '../../Forms/AuthForm'
import Card from '../../../components/UI/Card'

import gradient_background from '../../../images/auth_background.svg'

const StyledAuthPageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${gradient_background});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top left;
`

const FormTitle = styled.div`
  padding-top: 12px;

  text-align: center;
  color: #233539;

  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.63px;
`

const AuthPage = ({ t }) => (
  <StyledAuthPageWrapper>
    <Helmet>
      <title>Login - TNG Schedule</title>
    </Helmet>
    <Card width="374px" padVertical={25} padHorizontal={27}>
      <FormTitle>{t('auth:login')}</FormTitle>
      <AuthForm />
    </Card>
  </StyledAuthPageWrapper>
)

export default withTranslation()(AuthPage)
