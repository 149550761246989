import * as Sentry from '@sentry/browser'
import sentryDSN from '../sentryConfig'

export const captureException = (e) => {
  console.error(e)
  Sentry.captureException(e)
}

export default () => {
  Sentry.init({
    dsn: sentryDSN
  })
}
