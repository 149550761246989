export default {
  auth: {
    byEmail: 'အီးေမးသုိ႔လွ်ဳိ႔၀ွက္ကုဒ္ျဖင္႔ ၀င္ေရာက္မည္',
    byCard: 'ကဒ္နံပတ္ျဖင္႔ ၀င္ေရာက္မည္',
    login: 'ေလာ႔အင္၀င္ရန္',
    cardNumber: 'ကဒ္နံပတ္',
    email: 'အီးေမးလ္',
    password: 'လ်ွိဳ႔၀ွက္ကုဒ္',
    logout: 'ျပန္ထြက္ရန္'
  },
  calendar: {
    today: 'ယေန႔',
    day: 'ရက္',
    week: 'ပတ္',
    month: 'လ',
    controlData: (month, year) => `${month}လof ${year}နွစ္`,
    showMore: (count) => `အေပါင္း ${count} ပုိ၍`
  },
  booking: {
    loading: 'ေခတၱေစာင္႔ဆုိင္းရန္',
    bookNow: 'ဘြတ္ကင္လုပ္ရန္',
    details: 'ဘြတ္ကင္အေသးစိတ္',
    noEvent: 'ေရြးခ်ယ္ထားေသာ ပြဲမရွိပါ',
    minutesShort: 'မိနစ္',
    status: 'အေျခအေန',
    markCompleted: 'မွတ္သားပီးပါပီ',
    rebook: 'ျပန္၍ဘြတ္ကင္လုပ္သည္ ',
    time: 'အခ်ိန္',
    chooseTime: 'ဘြတ္ကင္ အခ်ိန္ေရြးခ်ယ္ပါ',
    noTimeAvail: 'အခ်ိန္ မရွိေတာ႔ပါ',
    client: 'ဧည္႔သည္',
    noClientSelected: 'ေရြးခ်ယ္ထားေသာဧည္႔သည္မရွိေသးပါ',
    noClientFound: 'ဧည္႔သည္မေတြ႔ပါ',
    offers: 'ဖိတ္ေခၚမွဳ',
    noOffersSelected: 'ဖိတ္ေခၚထားမွဳ မရွိေသးပါ',
    noOffersFound: 'ဖိတ္ေခၚမွဳကိုမေတြ႔ပါ'
  },
  schedule: {
    upcomingToday: 'လာမည္႔ေန႔',
    noUpcoming: 'ယေန႔အတြက္ ဘြတ္ကင္မရွိပါ'
  },
  statuses: {
    COMPLETE: 'ျပီးပါျပီ',
    INCOMPLETE: 'မျပီးေသးပါ',
    IN_PROCESS: 'အလုပ္လုပ္ေနပါသည္',
    CONFIRMED: 'ေသခ်ာသည္',
    NO_SHOW: 'မဆက္သြယ္ပါ',
    CANCEL: 'ပယ္ဖ်တ္သည္',
    DONE: 'ျပီးပါျပီ',
    UNDEFINED: 'မသတ္မွတ္ရေသးပါ',
    TENTATIVE: 'ယာယီ'
  }
}
