import React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { connect } from 'react-redux'

import { setActiveDate } from '../../ducks/calendar'

const StyledMonthDateHeader = styled.div`
  position: relative;
  margin: 4px 0 6px 0;

  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-transform: uppercase;
  cursor: pointer;

  .date {
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  ${(props) =>
    props.active &&
    css`
      .date {
        color: #fff;
        background-color: #4192ec;

        border-radius: 50%;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}

  .disabledBar {
    position: absolute;
    height: 1px;
    width: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);

    background-color: rgba(151, 151, 151, 0.5);
    transition: all 0.5s;

    /* @media (max-width: 930px) {
      &.active {
        width: 25px;
      }
    } */
  }
`

const StyledShift = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: #bdbdbd;
  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;

  max-width: 100%;
`

const MonthDateHeader = ({ date, setActiveDate, schedules }) => {
  const momentDate = moment(date)
  const momentDateString = momentDate.locale('en').format('DDMMYYYY')
  const dayName = momentDate.format('DD')

  const isActive = moment().isSame(momentDate, 'day')

  const utcOffset = momentDate.utcOffset()
  momentDate.add(utcOffset, 'minute')

  const daySchedule = schedules.entities[momentDateString]

  let scheduleString
  if (daySchedule) {
    let minHours
    let maxHours
    if (daySchedule.length > 1) {
      daySchedule.forEach(({ start, end }) => {
        if (!minHours) {
          minHours = moment(start)
        } else {
          const momentStart = moment(start)
          if (minHours.isAfter(momentStart)) {
            minHours = momentStart
          }
        }

        if (!maxHours) {
          maxHours = moment(end)
        } else {
          const momentEnd = moment(end)
          if (maxHours.isBefore(momentEnd)) {
            maxHours = momentEnd
          }
        }
      })
    } else {
      minHours = moment(daySchedule.start)
      maxHours = moment(daySchedule.end)
    }

    scheduleString = `${minHours.format('LT')} - ${maxHours.format('LT')}`
  }

  return (
    <StyledMonthDateHeader
      disabled={!daySchedule}
      onClick={daySchedule ? () => setActiveDate(momentDate) : null}
      active={isActive}
    >
      <span className="date">{dayName}</span>
      {daySchedule && <StyledShift>{scheduleString}</StyledShift>}
    </StyledMonthDateHeader>
  )
}

export default connect(
  ({ schedules }) => ({ schedules }),
  { setActiveDate }
)(MonthDateHeader)
