export default {
  auth: {
    byEmail: 'Войти по email и паролю',
    byCard: 'Войти по карте',
    login: 'Войти',
    cardNumber: 'Карта',
    email: 'Email',
    password: 'Пароль',
    logout: 'Выйти'
  },
  calendar: {
    today: 'Сегодня',
    day: 'День',
    week: 'Неделя',
    month: 'Месяц',
    controlData: (month, year) => `${month} ${year}`,
    showMore: (count) => `+ еще ${count}`
  },
  booking: {
    loading: 'Загрузка',
    bookNow: 'Забронировать',
    details: 'Детали бронирования',
    noEvent: 'Бронирование пока не выбрано',
    minutesShort: 'мин',
    status: 'Статус',
    markCompleted: 'Отметить завершение',
    rebook: 'Забронировать еще раз',
    time: 'Время',
    chooseTime: 'Выберите время начала',
    noTimeAvail: 'Нет доступного времени',
    client: 'Гость',
    noClientSelected: 'Гость не выбран',
    noClientFound: 'Гости не найдены',
    offers: 'Услуги',
    noOffersSelected: 'Услуга не выбрана',
    noOffersFound: 'Услуги не найдены'
  },
  schedule: {
    upcomingToday: 'Далее сегодня',
    noUpcoming: 'Нет бронирований на сегодня'
  },
  statuses: {
    COMPLETE: 'Забронировано',
    INCOMPLETE: 'Ограниченно забронировано',
    IN_PROCESS: 'В процессе',
    CONFIRMED: 'Подтверждено',
    NO_SHOW: 'Пропущено',
    CANCEL: 'Отменено',
    DONE: 'Выполнено',
    UNDEFINED: 'Не определено',
    TENTATIVE: 'Не подтверждено'
  }
}
