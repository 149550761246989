function apiError(data) {
  const { code, message } = data

  this.name = 'apiError'
  this.code = code
  this.message = message

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, apiError)
  } else {
    this.stack = new Error().stack
  }
}

apiError.prototype = Object.create(Error.prototype)

export default apiError
