import { axios, handleApiErrors, getToken } from '../helpers'

export default (client) => {
  const token = getToken()
  const app_id = window.appConfig.app_id

  const params = { session_id: token, client, app_id }

  return axios({
    url: 'checkin',
    method: 'POST',
    params
  }).then(handleApiErrors)
}
