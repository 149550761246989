import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  margin-bottom: 17px;
  display: flex;
  flex-direction: column;
`

const StyledTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
`

export default ({ label, children }) => {
  return (
    <StyledWrapper>
      <StyledTitle>{label}</StyledTitle>
      {children}
    </StyledWrapper>
  )
}
