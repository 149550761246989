import React, { PureComponent } from 'react'
import styled from 'styled-components'

import TicketDetails from './TicketDetails'
import TodaysSchedule from './TodaysSchedule'
import User from './User'

const StyledSidebarWrapper = styled.div`
  z-index: 6000;
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  flex: 0 0 280px;

  margin-left: 27px;
  box-sizing: content-box;
  width: 280px;
  height: 100vh;
  max-height: 100vh;

  overflow: hidden;

  background-color: #ffffff;
  box-shadow: 0 5px 0 5px rgba(0, 0, 0, 0.03);
`

class Sidebar extends PureComponent {
  render() {
    return (
      <StyledSidebarWrapper>
        <User />
        <TicketDetails />
        <TodaysSchedule />
      </StyledSidebarWrapper>
    )
  }
}

export default Sidebar
