export default {
  auth: {
    byEmail: '이메일과 비밀번호로 인증',
    byCard: '카드 별 인증',
    login: '로그인',
    cardNumber: '카드 번호',
    email: '이메일',
    password: '비밀번호',
    logout: '로그 아웃'
  },
  calendar: {
    today: '오늘',
    day: '일',
    week: '주',
    month: '월',
    controlData: (month, year) => `${year}년 ${month}월 `,
    showMore: (count) => ` ${count}개더`
  },
  booking: {
    loading: '로드 중',
    bookNow: '지금 예약',
    details: '예약 세부 정보',
    noEvent: '선택한 일정 없음',
    minutesShort: '분',
    status: '상태',
    markCompleted: '완성하다',
    rebook: '재계약',
    time: '시간',
    chooseTime: '예약 시간 선택',
    noTimeAvail: '사용할 수있는 시간이 없습니다',
    client: '손님',
    noClientSelected: '선택하지 손님 없습니다',
    noClientFound: '손님를 찾을 수 없습니다',
    offers: '제공',
    noOffersSelected: '선택에는 제공되지 없습니다',
    noOffersFound: '제공 찾을 수 없음'
  },
  schedule: {
    upcomingToday: '오늘 예약',
    noUpcoming: '오늘 예약이 없습니다.'
  },
  statuses: {
    COMPLETE: '완성한',
    INCOMPLETE: '완전하지 않은',
    IN_PROCESS: '진행 중',
    CONFIRMED: '확인 됨',
    NO_SHOW: '노쇼',
    CANCEL: '취소',
    DONE: '끝난',
    UNDEFINED: '정의되지 않음',
    TENTATIVE: '시험적인'
  }
}
