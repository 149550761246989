import React from 'react'
import styled, { css } from 'styled-components'

const StyledCard = styled.div`
  padding: ${(props) => props.padVertical}px ${(props) => props.padHorizontal}px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  width: ${(props) => props.width};

  ${(props) =>
    props.withOverflow &&
    css`
      overflow-y: hidden;
    `}

  ${(props) =>
    props.flex &&
    css`
      flex: 1 1 100%;
    `}

  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
`

export default ({
  overflow = false,
  children,
  flex = false,
  padHorizontal = 34,
  padVertical = 32,
  width = 'auto'
}) => {
  return (
    <StyledCard
      width={width}
      flex={flex}
      withOverflow={overflow}
      padHorizontal={padHorizontal}
      padVertical={padVertical}
    >
      {children}
    </StyledCard>
  )
}
