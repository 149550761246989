import { axios, handleApiErrors, getToken } from '../helpers'

export default ({ from = '11032019', to = '17032019', lang = 'en_GB' }) => {
  const token = getToken()
  const app_id = window.appConfig.app_id

  const params = { session_id: token, from, to, lang, app_id }

  return axios({
    url: 'bookings',
    method: 'GET',
    params
  }).then(handleApiErrors)
}
