import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'

import createRootReducer from './reducer'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga'
import history from '../history'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(routerMiddleware(history), sagaMiddleware)
)

const store = createStore(createRootReducer(history), {}, enhancer)

sagaMiddleware.run(rootSaga)

export default store
