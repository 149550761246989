import { all, put, call, takeEvery } from 'redux-saga/effects'
import moment from 'moment'

import { getSchedules } from '../api'
import { apiError, showPopup } from '../helpers'
import { captureException } from '../helpers/sentry'

import { logout, LOGOUT } from './auth'

export const initialState = {
  entities: {},
  min: new Date(2017, 10, 0, 8, 0, 0),
  max: new Date(2017, 10, 0, 22, 59, 0),
  loading: false,
  error: null
}

export const moduleName = 'schedules'

export const FETCH_SCHEDULES_REQUEST = `${moduleName}/FETCH_SCHEDULES_REQUEST`
export const FETCH_SCHEDULES_SUCCESS = `${moduleName}/FETCH_SCHEDULES_SUCCESS`
export const FETCH_SCHEDULES_FAIL = `${moduleName}/FETCH_SCHEDULES_FAIL`

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_SCHEDULES_REQUEST:
      return { ...state, loading: true, error: null }
    case FETCH_SCHEDULES_SUCCESS:
      return { ...state, loading: false, error: null, ...payload }
    case FETCH_SCHEDULES_FAIL:
      return { ...state, loading: false, error: payload }

    case LOGOUT:
      return {
        ...initialState
      }

    default: {
      return state
    }
  }
}

export const fetchSchedules = () => ({
  type: FETCH_SCHEDULES_REQUEST
})

export const fetchSchedulesSaga = function*({ to, from }) {
  try {
    const formattedFrom = moment(from)
      .locale('en')
      .format('DDMMYYYY')
    const formattedTo = moment(to)
      .locale('en')
      .format('DDMMYYYY')

    const response = yield call(getSchedules, {
      from: formattedFrom,
      to: formattedTo
    })

    let minHours = 23
    let maxHours = 0

    const calculateHoursBoundary = ({ start, end }) => {
      const startHour = moment(start).hours()
      const endHours = moment(end).hours()

      if (startHour < minHours) {
        minHours = startHour
      }
      if (endHours > maxHours) {
        maxHours = endHours
      }
    }

    const transformedSchedule = response.reduce((acc, { start, end }) => {
      const dateString = moment(start)
        .locale('en')
        .format('DDMMYYYY')

      const dateInAcc = acc[dateString]
      calculateHoursBoundary({ start, end })

      if (dateInAcc) {
        if (dateInAcc.length) {
          dateInAcc.push({ start, end })
        } else {
          acc[dateString] = [{ ...dateInAcc }, { start, end }]
        }
      } else {
        acc[dateString] = { start, end }
      }

      return acc
    }, {})

    const min = new Date(2017, 10, 0, minHours, 0, 0)
    const max = new Date(2017, 10, 0, maxHours - 1, 59, 0)

    yield put({
      type: FETCH_SCHEDULES_SUCCESS,
      payload: { entities: transformedSchedule, min, max }
    })
  } catch (e) {
    if (e instanceof apiError) {
      const { code, message } = e
      yield put({
        type: FETCH_SCHEDULES_FAIL,
        payload: {
          code,
          message
        }
      })
      if (code === 4) {
        yield put(logout())
      }
    } else {
      yield put({
        type: FETCH_SCHEDULES_FAIL,
        payload: {
          code: 0,
          message: 'unknown error'
        }
      })

      captureException(e)
    }
    yield put(showPopup(e))
  }
}

export const saga = function*() {
  yield all([takeEvery(FETCH_SCHEDULES_REQUEST, fetchSchedulesSaga)])
}
